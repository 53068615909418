// FONT FACE
@include font-face('UTM Bebas', '../fonts/UTM-Bebas/UTM-Bebas', normal, normal, ttf);
@include font-face('Roboto Medium', '../fonts/Roboto/Roboto-Medium', normal, normal, ttf);

// FONTS
$fr: inherit;
$fm: inherit;
$fb: inherit;
$fsr: inherit;
$fsb: inherit;
$fs: 'UTM Bebas';
$ficon: 'FontAwesome';

.f {
	&r {
		font-family: $fr; }
	&b {
		font-family: $fb; }
	&sr {
		font-family: $fsr; }
	&sb {
		font-family: $fsb; }
	&m {
		font-family: $fm; } }

.font- {
	&italic {
		font-style: italic; } }

.text-underline {
	text-decoration: underline; }

.fw {
	&-6 {
		font-weight: 600; } }

.fs {
	&_12 {
		font-size: 12*$rem; }
	&_13 {
		font-size: 13*$rem; }
	&_14 {
		font-size: 14*$rem; }
	&_15 {
		font-size: 15*$rem; }
	&_16 {
		font-size: 1rem; }
	&_18 {
		font-size: 1rem;
		@include media(lg, emp, 0) {
			font-size: 18*$rem; } }
	&_20 {
		font-size: 18*$rem;
		@include media(lg, emp, 0) {
			font-size: 20*$rem; } }
	&_22 {
		font-size: 18*$rem;
		@include media(lg, emp, 0) {
			font-size: 22*$rem; } }
	&_24 {
		font-size: 18*$rem;
		@include media(lg, emp, 0) {
			font-size: 24*$rem; } }
	&_30 {
		font-size: 20*$rem;
		@include media(lg, emp, 0) {
			font-size: 30*$rem; } }
	&_32 {
		font-size: 24*$rem;
		@include media(sm, emp, 0) {
			font-size: 32*$rem; } }
	&_35 {
		font-size: 24*$rem;
		@include media(sm, emp, 0) {
			font-size: 35*$rem; } }
	&_40 {
		font-size: 24*$rem;
		@include media(sm, emp, 0) {
			font-size: 40*$rem; } }
	&_50 {
		font-size: 24*$rem;
		@include media(sm, emp, 0) {
			font-size: 50*$rem; } }
	&_70 {
		font-size: 24*$rem;
		@include media(sm, emp, 0) {
			font-size: 70*$rem; } } }
