@keyframes transUp {
	0% {
		transform: translateY(0); }
	100% {
		transform: translateY(-2px); } }

.transUp {
	transition: all 350ms;

	&:hover {
		animation: transUp 350ms ease-in-out 0s forwards; } }

@keyframes fadeUp {
	0% {
		opacity: 0;
		transform: translateY(1rem); }
	100% {
		opacity: 1;
		transform: translateY(0); } }

@keyframes fadeDown {
	0% {
		opacity: 0;
		transform: translateY(-50px); }
	100% {
		opacity: 1;
		transform: translateY(0); } }

@keyframes fadeLeft {
	0% {
		opacity: 0;
		transform: translateX(30px); }
	100% {
		opacity: 1;
		transform: translateX(0); } }

@keyframes fadeMInDown {
	0% {
		opacity: 0;
		margin-top: -30px; }
	100% {
		opacity: 1;
		margin-top: 0; } }

@keyframes dropDown {
	0% {
		opacity: 0;
		transform: translateY(-30px); }
	60% {
		opacity: 1;
		transform: translateY(0); }
	80% {
		opacity: 1;
		transform: translateY(-10px); }
	100% {
		opacity: 1;
		transform: translateY(0); } }

@keyframes dropUp {
	0% {
		opacity: 0;
		transform: scale(0); }
	60% {
		opacity: 1;
		transform: scale(1.15); }
	80% {
		opacity: 1;
		transform: scale(0.9); }
	100% {
		opacity: 1;
		transform: translateY(1); } }

@keyframes scaleIn {
	0% {
		transform: scale(0);
		opacity: 0; }
	100% {
		transform: scale(1);
		opacity: 1; } }

@keyframes scaleOut {
	0% {
		transform: scale(1);
		opacity: 1; }
	100% {
		transform: scale(0);
		opacity: 0; } }

@keyframes scaleDown {
	0% {
		transform: scale(1.2, 1.2) translate(-50%, -50%); }
	100% {
		transform: scale(1, 1) translate(-50%, -50%); } }
