// BANNER
.banner {
	&-main {
		.slick-dots {
			@include align(hor);
			bottom: 0.5rem; }

		@include media(sm, emp, 0) {
			.slick-dots {
				bottom: 2rem; } } } }
