// PRODUCT'S STYLES
.product {
	&-panel {
		padding: 0 1rem 1rem;
		border: 1px solid $cl_ee;

		~.product-panel {
			border-top: none; }

		.title {
			&-wrap {
				margin: 0 -1rem 1.5rem;
				padding: 0.5rem	1rem;
				border-bottom: 1px solid $cl_ee;
				color: $cl_red; }

			&:before {
				content: "\f101";
				font-family: $ficon;
				margin-right: 0.5rem; } }

		&__inner {
			padding: 1.25rem 1rem 0; }

		&.style2 {
			padding: 0;

			~.product-panel {
				margin-top: 30*$rem;
				border-top: 1px solid $cl_e1; }

			>.title {
				padding: 0.5rem 1rem;
				color: $cl_main;
				font-size: 14*$rem;
				font-weight: bold;
				border-bottom: 1px solid $cl_e1;
				display: flex;
				line-height: 1.7;

				&:before {
					content: "\f196";
					flex: 0 0 1.25rem;
					max-width: 1.25rem;
					margin-right: 0;
					font-weight: normal; } }

			.name {
				text-transform: capitalize; } }

		@include media(sm, emp, 0) {
			padding-left: 1.75em;
			padding-right: 1.75em;

			.title-wrap {
				margin-left: -1.75rem;
				margin-right: -1.75rem;
				padding-left: 1.75rem;
				padding-right: 1.75rem; }

			&__inner {
				padding-left: 1.75rem;
				padding-right: 1.75rem; }

			&.style2 {
				>.title {
					padding-left: 1.75rem;
					padding-right: 1.75rem; } } } }

	&-item {
		margin-bottom: 1.5rem;

		.name {
			font-weight: bold;
			margin-bottom: 1rem;
			display: block;
			// white-space: nowrap
			// text-overflow: ellipsis
 }			// overflow: hidden

		.wrap {
			display: flex;
			align-items: flex-start;

			.img {
				flex: 0 0 110*$rem;
				max-width: 110*$rem;
				height: 110*$rem;
				margin-bottom: 0; }

			.summary {
				padding-left: 1rem;
				margin-bottom: 0;
				font-size: 14*$rem;
				line-height: 1.7;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical;
				overflow: hidden;
				display: -webkit-box; } } }

	&-list {
		margin-bottom: 15*$rem;

		.product-list {
			margin-top: 30*$rem; }

		.title {
			font-size: 14*$rem;
			font-weight: bold;
			margin-bottom: 12*$rem;
			display: flex;
			line-height: 1.7;
			text-transform: uppercase;

			&:before {
				content: "\f005";
				color: $cl_main;
				flex: 0 0 1.25rem;
				max-width: 1.25rem;
				margin-right: 0; } }

		.list {
			font-size: 14*$rem;
			text-transform: capitalize; }

		.item {
			margin-bottom: 15*$rem;
			display: flex;
			align-items: baseline;

			&:before {
				content: "\f111";
				font-family: $ficon;
				font-size: 0.25rem;
				flex: 0 0 1.25rem;
				max-width: 1.25rem;
				margin-bottom: 0.125rem;
				padding-left: 0.25rem; } } }

	&-item2 {
		display: flex;
		border: 1px solid $cl_ee;
		border-top: none;
		flex-direction: column;

		.img {
			flex: 0 0 150*$rem;
			max-width: 150*$rem;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: auto; }

		.content {
			display: flex;
			flex-direction: column;
			padding: 15*$rem 11*$rem 15*$rem 15*$rem;
			font-size: 14*$rem;
			width: 100%;

			p {
				margin-bottom: 0.25rem; }

			.price {
				color: $cl_red;
				font-weight: bold; }

			.name {
				font-size: 15*$rem;
				font-weight: bold;
				color: $cl_main; }

			.code {
				font-weight: bold; }

			.btn-main {
				height: 2.5rem;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: bold;
				padding: 0;
				margin-top: 14*$rem;
				border-radius: 0.375rem; } }

		@include media(sm, emp, 0) {
			flex-direction: row;

			.img {
				margin: 0;
				border-right: 1px solid $cl_ee; }

			.content {
				padding-top: 30*$rem;
				padding-bottom: 30*$rem;
				flex-direction: row; } }

		&__col {
			padding-left: 0.5$rem;
			padding-right: 0.5$rem;
			margin-bottom: 0.5rem;

			@include media(md, emp, 0) {
				&:nth-child(1) {
					flex: 0 0 36%;
					max-width: 36%; }

				&:nth-child(2) {
					flex: 0 0 34%;
					max-width: 34%; }

				&:nth-child(3) {
					flex: 0 0 30%;
					max-width: 30%; } }

			@include media(lg, emp, 0) {
				padding-left: 15*$rem;
				padding-right: 15*$rem;
				margin-bottom: 0;

				&:nth-child(1) {
					flex: 0 0 43%;
					max-width: 43%; }

				&:nth-child(2) {
					flex: 0 0 31%;
					max-width: 31%; }

				&:nth-child(3) {
					flex: 0 0 26%;
					max-width: 26%; } } } }

	&-table {
		background-color: $cl_bg_modal;
		overflow-y: auto;
		max-height: 310*$rem;

		@include media(sm, emp, 0) {
			max-height: 413*$rem; }

		&__row {
			display: flex;
			padding: 0.5rem;
			padding-bottom: 0.5rem;

			~.product-table__row {
				border-bottom: 1px solid #d3ded2; }

			.item {
				width: 100%;
				display: flex;
				align-items: center;
				margin-bottom: 0.5rem; }

			// 	&:nth-child(1),
			// 	&:nth-child(4)
			// 		flex: 0 0 50*$rem
			// 		max-width: 50*$rem
			// 		justify-content: center

			// 	&:nth-child(3)
			// 		flex: 0 0 98*$rem
			// 		max-width: 98*$rem

			.item {
				// &:before
				// 	content: attr(data-title)
				// 	flex: 0 0 90*$rem
				// 	max-width: 90*$rem

				&:nth-child(1) {
					flex: 0 0 1.5rem;
					max-width: 1.5rem; }

				&:nth-child(4) {
					flex: 0 0 1.5rem;
					max-width: 1.5rem;
					justify-content: flex-end; }

				&:nth-child(3) {
					flex: 0 0 50*$rem;
					max-width: 50*$rem; } }

			.form-control {
				max-width: 50*$rem;
				flex: 0 0 50*$rem;
				height: 34*$rem;
				text-align: center; }

			.img {
				height: 50*$rem;
				flex: 0 0 50*$rem;
				max-width: 50*$rem;
				padding: 0.25rem;
				background-color: $cl_white;
				margin-bottom: 0; }

			.content {
				padding-left: 0.5rem;
				font-size: 13*$rem;

				.name {
					font-weight: bold;
					color: $cl_main; } }

			&.head {
				display: none;
				color: $cl_white;
				background-color: $cl_main;
				position: sticky;
				top: 0;

				.item {
					justify-content: center;
					padding-top: 0;
					padding-bottom: 0;
					min-height: 40*$rem;

					&+.item {
						border-left: 1px solid $cl_bg_modal; } } }

			&.foot {
				flex-direction: row;
				border-bottom: none;
				justify-content: flex-end;
				position: sticky;
				bottom: 0;
				z-index: 1;
				background-color: $cl_bg_modal;

				.item {
					font-weight: bold;
					min-height: 40px;
					padding-top: 0;
					padding-bottom: 0;

					&:first-child {
						justify-content: flex-start;
						flex: 0 0 100%;
						max-width: 100%; }

					&:before {
						display: none; }

					.value {
						min-width: 70*$rem;
						text-align: center; } } }

			@include media(xs, emp, 0) {
				flex-direction: row;
				padding: 0;

				.item {
					width: 100%;
					padding-top: 1.25rem;
					padding-bottom: 1.25rem;
					min-height: 110*$rem;
					display: flex;
					align-items: center;
					margin-bottom: 0;

					&:before {
						display: none; }

					&:nth-child(1),
					&:nth-child(4) {
						flex: 0 0 50*$rem;
						max-width: 50*$rem;
						justify-content: center; }

					&:nth-child(3) {
						flex: 0 0 98*$rem;
						max-width: 98*$rem; } }

				.form-control {
					height: 2.5rem;
					max-width: 70*$rem;
					flex: 0 0 70*$rem;
					margin-left: auto;
					margin-right: auto; }

				.img {
					height: 70*$rem;
					flex: 0 0 70*$rem;
					max-width: 70*$rem;
					padding: 0.5rem; }

				.content {
					padding-left: 1.5rem;
					font-size: 15*$rem; }

				&.head {
					display: flex; }

				&.foot {
					.item {
						&:first-child {
							flex: 0 0 212*$rem;
							max-width: 212*$rem; } } } } } }

	&-modal {
		.modal {
			&-header {
				position: relative;
				min-height: 50*$rem;
				display: flex;
				align-items: center;
				padding: 0 1.25rem;
				font-size: 18*$rem;
				color: $cl_00;
				background-color: $cl_bg_modal;

				.fa {
					margin-right: 10*$rem;
					color: $cl_red; }

				.close {
					opacity: 1;
					float: none;
					width: 2rem;
					height: 33*$rem;
					background: url(../img/root/icon-close-modal.png) no-repeat center / cover;
					position: absolute;
					top: -0.725rem;
					right: -0.725rem;
					margin: 0; } }

			&-body,
			&-footer {
				padding: 0; }

			&-footer {
				margin-top: auto;
				background-color: $cl_bg_modal;
				padding: 1.25rem 12*$rem 0.75rem;
				flex-direction: column;

				>:not(:first-child) {
					margin-left: 0; }

				>:not(:last-child) {
					margin-right: 0; }

				.form-request {
					width: 100%; }

				.ctrl {
					display: flex;
					justify-content: space-between;
					width: 100%;
					font-size: 14*$rem;

					.back,
					.remove-all {
						margin-bottom: 0.5rem; }

					.fa {
						margin-right: 10*$rem; }

					.back {
						color: $cl_cyanl; }

					.remove-all {
						color: $cl_red; } } }

			&-dialog {
				width: 770*$rem;
				max-width: calc(100% - 1.25rem); }

			&-title {
				text-align: center;
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;

				@include media(sm, emp, 0) {
					text-align: left;
					padding-bottom: 0;
					padding-top: 0; } }

			&-content {
				display: flex;
				flex-direction: column;

				@include media(sm, emp, 0) {
					min-height: 550*$rem; } } } }

	&-general {
		padding-top: 30*$rem;

		.img {
			flex: 0 0 270*$rem;
			max-width: 270*$rem;
			height: 270*$rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $cl_ee;
			margin-left: auto;
			margin-right: auto; }

		.title {
			color: $cl_cyanl;
			text-transform: uppercase;
			font-size: 15*$rem;
			font-weight: bold;
			padding-top: 0.25rem;
			margin-bottom: 0.5rem; }

		.summary {
			font-size: 14*$rem;

			p {
				margin-bottom: 0.5rem; } }

		.block {
			&:not(:last-child) {
				margin-bottom: 1rem; } }

		~.social-share {
			margin-top: 1.5rem; }

		@include media(xs, emp, 0) {
			display: flex;
			align-items: flex-start;

			.img {
				margin: 0; }

			.content {
				padding-left: 30*$rem; } } }

	&-document {
		padding-top: 1.75rem;

		.title {
			font-size: 15*$rem;
			color: $cl_cyanl;
			font-weight: bold;
			margin-bottom: 1rem; }

		&__table {
			border: 1px solid $cl_e1; }

		&__row {
			display: flex;
			align-items: center;
			padding: 0.5rem 1rem;
			flex-direction: column;

			~.product-document__row {
				border-top: 1px solid $cl_e1; }

			.fa {
				color: $cl_red;
				margin-right: 0.25rem; }

			.item {
				display: flex;
				width: 100%;
				margin-bottom: 0.5rem;

				&:before {
					content: attr(data-title);
					flex: 0 0 110*$rem;
					max-width: 110*$rem;
					font-weight: bold; }

				@include media(xs, emp, 0) {
					padding-right: 10*$rem;
					display: block;
					margin-bottom: 0;

					&:before {
						display: none; }

					&:nth-child(1) {
						flex: 0 0 3rem;
						max-width: 3rem;
						text-align: center;
						padding-right: 0; }

					&:nth-child(2),
					&:nth-child(4),
					&:nth-child(5) {
						flex: 0 0 6rem;
						max-width: 6rem; } }

				@include media(sm, emp, 0) {
					&:nth-child(1) {
						flex: 0 0 70*$rem;
						max-width: 70*$rem; }

					&:nth-child(2),
					&:nth-child(4) {
						flex: 0 0 144*$rem;
						max-width: 144*$rem; }

					&:nth-child(5) {
						flex: 0 0 116*$rem;
						max-width: 116*$rem; } } }

			&.head {
				background-color: $cl_f0;
				font-weight: bold;
				display: none; }

			@include media(xs, emp, 0) {
				min-height: 2.5rem;
				flex-direction: row;
				padding-left: 0;
				padding-right: 0;

				&.head {
					display: flex; } } } }

	&-related {
		.title {
			font-weight: bold;
			margin-bottom: 0.75rem;
			font-size: 15*$rem; }

		.product-item2 {
			border-top: 1px solid $cl_ee;

			&__col {
				@include media(sm, emp, 0) {
					&:nth-child(3) {
						flex: 0 0 180*$rem;
						max-width: 180*$rem; } }

				@include media(md, emp, 0) {
					&:nth-child(1) {
						width: 100%;
						flex: auto;
						max-width: none; }

					&:nth-child(2) {
						flex: 0 0 30%;
						max-width: 30%; } } } } }

	&-detail {
		.block {
			&:not(:last-child) {
				margin-bottom: 1rem; } }

		.top {
			margin-bottom: 2rem !important;

			.img {
				height: 320*$rem;
				width: 320*$rem;
				border: 1px solid $cl_ee;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0.5rem;
				margin: auto auto 1.25rem; }

			.content {
				padding-top: 0.5rem;
				font-size: 14*$rem; }

			.cap {
				font-weight: bold;
				flex: 0 0 145*$rem;
				max-width: 145*$rem; }

			.price {
				font-size: 1.5rem;
				font-weight: bold;
				color: $cl_red; }

			.fa-reply {
				transform: rotateY(180deg);
				margin-right: 0.5rem; }

			.link {
				color: $cl_7e;
				font-size: 14*$rem;

				&:hover {
					color: $cl_red; } }

			.btn {
				&-cyan {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					min-height: 2.5rem;
					margin-top: 1.25rem;
					font-weight: bold; }

				&-main {
					flex: 0 0 190*$rem;
					max-width: 190*$rem;
					padding: 0;
					border-radius: 0.25rem; }

				&-gray {
					flex: 0 0 128*$rem;
					max-width: 128*$rem; }

				&-main,
				&-gray {
					min-height: 2.5rem;
					display: flex;
					align-items: center;
					justify-content: center;
					font-weight: bold;

					.fa {
						margin-right: 0.5rem; } } }

			@include media(sm, emp, 0) {
				display: flex;
				align-items: flex-start;

				.img {
					height: 380*$rem;
					flex: 0 0 380*$rem;
					max-width: 380*$rem;
					margin: 0;
					width: auto; }

				.content {
					flex: 0 0 360*$rem;
					max-width: 360*$rem;
					padding-left: 1.25rem; } }

			@include media(md, emp, 0) {
				.img {
					height: 470*$rem;
					flex: 0 0 470*$rem;
					max-width: 470*$rem; }

				.content {
					flex: 0 0 400*$rem;
					max-width: 400*$rem;
					padding-left: 66*$rem; } } } } }

.img {
	img {
		transition: filter 250ms; }

	&:hover {
		img {
			filter: brightness(0.9); } } }

// QUANTITY
.quantity {
	display: flex;
	height: 2.5rem;
	border: 1px solid $cl_ee;
	border-radius: 0.375rem;
	overflow: hidden;

	&-btn {
		@extend .reset-btn;
		flex: 0 0 2.5rem;
		max-width: 2.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.25rem;
		background-color: $cl_f0; }

	&-val {
		width: 100%;
		border: 1px solid $cl_ee;
		text-align: center; } }
