.hasLink {
	position: relative;

	&>.link {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		opacity: 0;
		z-index: 2; } }

.hasBg {
	.link-bg {
		z-index: 1; }

	@include min(1024px) {
		.link-bg {
			background-color: rgba(#000, .5);
			opacity: 0; }

		&:hover {
			.link-bg {
				opacity: 1; } } } }

.link {
	&-line {
		color: $cl_22;
		position: relative;
		font-size: 12px;
		padding-left: 80px;

		&:before {
			content: "";
			@include align(ver);
			left: 0;
			background-color: $cl_main;
			border-right: 4px solid $cl_22;
			box-shadow: -3px 0 0 0 #fff inset;
			width: 60px;
			height: 2px;
			display: inline-block; } }

	&:hover {
		color: $cl_red; } }

// .more
// 	color: $cl_blue

// 	.zmdi
// 		margin-left: 0.5rem

/// &:hover
/// 	color: $cl_blue

/// +media(sm, emp, -1)
/// 	border: 1px solid $cl_blue
/// 	text-align: center
/// 	width: 100%
/// 	display: block
/// 	background-color: $cl_white
/// 	padding: 0.5rem 1rem
