// TITLE STYLES
.title {
	&-wrap {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding-bottom: 1.25rem;

		.title {
			margin-bottom: 0;
			font-size: 18*$rem;
			font-weight: bold; }

		.link {
			margin-left: auto;
			color: $cl_main; } }

	&-detail {
		font-size: 18*$rem;
		text-transform: uppercase;
		color: $cl_red;
		font-weight: bold;
		margin-bottom: 1rem;
		padding-top: 0.5rem;

		&-sm {
			font-size: 15*$rem;
			color: $cl_00;
			font-weight: bold;
			text-transform: uppercase;
			margin-bottom: 0.75rem; } } }
