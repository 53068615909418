// SELECT STYLES
.selectbox {
	position: relative;
	height: 40*$rem;
	display: flex;
	align-items: center;
	margin-bottom: 0;
	border: 1px solid rgba(48,51,58,.1);
	border-radius: 4*$rem;

	&:after {
		content: "\f2f9";
		font-family: $ficon;
		font-size: 20*$rem;
		margin-right: 0.5rem;
		@include align(ver);
		right: 0; }

	select {
		border: none;
		box-shadow: none;
		background: transparent;
		background-image: none;
		appearance: none;
		padding: 0 1.5rem 0 1rem;
		width: 100%;
		height: 100%;
		color: inherit; } }

// CHECKBOX
.checkbox {
	user-select: none;
	display: flex;
	align-items: baseline;

	&-input {
		display: none;

		&:checked {
			&~.checkbox-text {
				&:before {
					background-color: $cl_33;
					font-size: 1rem; } } } }

	&-text {
		display: flex;
		width: 100%;

		&:before {
			content: "\f26b";
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 1;
			font-family: $ficon;
			height: 1rem;
			flex: 0 0 1rem;
			max-width: 1rem;
			margin-right: 0.5rem;
			border-radius: 2px;
			border: 2px solid $cl_33;
			font-size: 0;
			transition: all 150ms;
			color: $cl_white; } }

	&.radio {
		.checkbox-text {
			&:before {
				border-radius: 50%; } } } }

// FORM
.form {
	&-control {
		border-color: $cl_e1;

		&:focus {
			border-color: $cl_main; } }

	&-search {
		display: flex;

		.input {
			height: 100%;
			width: 100%;
			border-radius: 0.375rem 0 0 0.375rem;
			padding-left: 1.25rem;
			padding-right: 1.25rem;
			color: $cl_73;
			font-size: 13*$rem;
			border: 1px solid $cl_e1; }

		.button {
			height: 100%;
			width: 100%;
			max-width: 2.5rem;
			border-radius: 0 0.375rem 0.375rem 0;
			color: $cl_white;
			background-color: $cl_main;
			display: flex;
			align-items: center;
			justify-content: center;
			border: none;
			padding: 0;
			font-size: 0;

			.fa {
				font-size: 1rem; } }

		@include media(md, emp, 0) {
			.button {
				font-size: 15*$rem;
				max-width: 130*$rem;

				.fa {
					margin-right: 1px; } } } }

	// &-contact
	// 	max-width: 360*$rem
	// 	margin-left: auto
	// 	margin-right: auto
	// 	border: 1px solid $cl_a5
	// 	padding: 1.75rem

	&-contact {
		.row {
			margin-left: -5px;
			margin-right: -5px; }

		[class*="col-"] {
			padding-left: 5px;
			padding-right: 5px; }

		&__row {
			margin-bottom: 10*$rem; }

		.form-control {
			height: 2.5rem;
			border-color: $cl_e1;

			&.txt {
				height: 190*$rem; } }

		.btn-main {
			text-align: center;
			width: 100%;
			transition: filter 350ms;
			border-radius: 0.25rem;
			font-weight: bold;

			&:hover {
				filter: brightness(110%); } } }

	&-request {
		.row {
			margin-left: -6px;
			margin-right: -6px; }

		[class*="col-"] {
			padding-left: 6px;
			padding-right: 6px;
			margin-bottom: 1rem; }

		.col-4,
		.col-2 {
			flex: 0 0 100%;
			max-width: 100%; }

		.form-control {
			font-size: 13*$rem;
			height: 2.5rem; }

		.btn-main {
			padding: 0;
			font-size: 14*$rem;
			font-weight: bold;
			height: 2.5rem;
			width: 100%;
			border-radius: 0.325rem; }

		@include media(xs, emp, 0) {
			.col-4 {
				flex: 0 0 33.333%;
				max-width: 33.333%; }

			.col-2 {
				flex: 0 0 33.333%;
				max-width: 33.333%;

				&:last-child {
					flex: 0 0 100%;
					max-width: 100%; } } }

		@include media(sm, emp, 0) {
			.col-4 {
				flex: 0 0 31.8%;
				max-width: 31.8%; }

			.col-2 {
				flex: 0 0 18%;
				max-width: 18%;

				&:last-child {
					flex: 0 0 18%;
					max-width: 18%; } } } } }

// SEACH BOX
.search {
	.tab {
		&-parent {
			display: flex; }

		&-nav {
			flex: 130*$rem;
			max-width: 130*$rem;

			.button {
				text-align: center;
				height: 100%;
				width: 100%;
				font-size: 14*$rem;
				color: $cl_white;
				background-color: $cl_main;
				line-height: 1;

				&:after {
					vertical-align: middle; } } }

		&-content {
			width: 100%; } }

	&box {
		background-color: $cl_f5;
		display: flex;
		width: 100%;
		height: 3.5rem;
		padding: 0.3rem 0.5rem;

		.input {
			width: 100%;
			padding: 0.5rem 1rem;
			border: 1*$rem solid $cl_e6; }

		.button {
			flex: 0 0 3.5rem;
			max-width: 3.5rem;
			color: $cl_white;
			background-color: $cl_main;
			font-size: 0;

			.zmdi {
				font-size: 1rem; } }

		@include media(xs, emp, 0) {
			.button {
				flex: 0 0 7.5rem;
				max-width: 7.5rem;
				font-size: 1rem;

				.zmdi {
					margin-right: 0.5rem; } } } } }
