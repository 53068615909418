// ***** MEDIA
$media-types: (fhd:1920px, xl:1440px, lg:1200px, hd:1024px, md:992px, sm:768px, xs:576px, xs-640:640px, xs-480:480px, xs-414:414px, xs-360:360px, emp:0);

@mixin media($media_min, $media_max, $type) {
	@if $media_max == emp {
		@if $type == -1 {
			@media screen and (max-width +':'+ #{map-get($media-types, $media_min) - 1}) {
				@content; } }
		@else if $type == 1 {
			@media screen and (max-width +':'+ #{map-get($media-types, $media_min)}) {
				@content; } }
		@else {
			@media screen and (min-width +':'+ #{map-get($media-types, $media_min)}) {
				@content; } } }
	@else {
		@media screen and (min-width +':'+ #{map-get($media-types, $media_min)}) and (max-width +':'+ #{map-get($media-types, $media_max) - 1}) {
			@content; } } }
// Ex.
// +media(md,lg,0) => @media screen and (min-width: 992px) and (max-width: 1199px)
// +media(md,emp,0) => @media screen and (min-width: 992px)
// +media(md,emp,1) => @media screen and (max-width: 992px)
// +media(md,emp,-1) => @media screen and (max-width: 991px)

//media robot
//sm - 576
@mixin max-sm() {
	@media screen and (max-width: 575px) {
		@content; } }

@mixin min-sm() {
	@media screen and (min-width: 576px) {
		@content; } }


// md - 768
@mixin max-md() {
	@media screen and (max-width: 767px) {
		@content; } }

@mixin min-md() {
	@media screen and (min-width: 768px) {
		@content; } }

// lg - 992
@mixin max-lg() {
	@media screen and (max-width: 991px) {
		@content; } }

@mixin min-lg() {
	@media screen and (min-width: 992px) {
		@content; } }

// xl - 1200
@mixin max-xl() {
	@media screen and (max-width: 1199px) {
		@content; } }

@mixin min-xl() {
	@media screen and (min-width: 1200px) {
		@content; } }


@mixin max($screen) {
	@media screen and (max-width: $screen) {
		@content; } }

@mixin min($screen) {
	@media screen and (min-width: $screen) {
		@content; } }
