.dropdown {
	font-size: 13px;

	&__btn {
		position: relative;
		border: 1px solid #ebebeb;
		padding: 3px 15px 3px 7px;
		cursor: pointer;

		i {
			position: absolute;
			top: 50%;
			right: 7px;
			font-size: 15px;
			transform: translateY(-50%); } }

	&__menu {
		border: 1px solid #e8e8e8;
		opacity: 0;
		transition: all .3s;
		visibility: hidden;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		z-index: 3;
		background-color: #fff; }

	&.active {
		.dropdown__menu {
			opacity: 1;
			visibility: visible; }

		.dropdown__item {
			a {
				height: 35px;
				padding: 3px 7px; } }

		i {
			&:before {
				content: "\f106"; } } }

	&__item {
		& ~ .dropdown__item {
			a {
				border-top: 1px solid #e8e8e8; } }

		a {
			padding: 0 7px;
			height: 0;
			transition: all .3s;
			line-height: 30px;
			color: #bfbfbf; }

		@include min(1024px) {
			&:hover {
				a {
					background-color: #000;
					color: #fff; } } }

		&.active {
			a {
				background-color: #000;
				color: #fff; } } } }

// DROPDOWN - DROPDOWN LIST
.drop {
	position: relative;

	&-list {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 5;
		min-width: 3rem;
		background-color: rgba(35, 40, 45, 0.8);
		// box-shadow: 0 0 10px rgba($cl_33, 0.5)

		.item {
			&:not(:last-child) {
				margin-bottom: 0.5rem; } }

		.link {
			// color: $cl_33
			white-space: nowrap;
			display: block;

			&:hover {
				color: $cl_main; } }

		&.child {
			right: auto;
			left: 100%;
			top: 0; } }

	&-btn {
 }		// display: inline-block

	&.right {
		.drop-list {
			right: 0;
			left: auto; } }

	@include media(md, emp, 0) {
		&-list {
			display: block;
			visibility: hidden;
			// transform: rotateX(90deg)
			// transform-origin: top
			opacity: 0;
			padding: 1rem; }

		&:hover {
			>.drop-list {
				visibility: visible;
				// transition: transform 300ms
				// transform: rotateX(0)
				animation: fadeUp 250ms linear 0s forwards; } } } }
