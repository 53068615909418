body {
	font-family: Arial, Helvetica, sans-serif;
	color: $cl_00;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	font-size: 15*$rem;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		background-color: rgba(0,0,0,.5);
		transition: all 350ms linear 20ms;
		z-index: 5; }

	&.nav-opened {
		overflow: hidden;

		&:before {
			width: 100%; }

		.header {
			&:before {
				width: 100%; } } } }

.container {
	max-width: 100%;

	@include media(lg, emp, 0) {
		max-width: 1200px; } }

.form-control {
	font-size: inherit; }

.nowrap {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis; }

.back2top {
	cursor: pointer;
	position: fixed;
	transition: all 200ms ease-in-out 200ms;
	z-index: 10;
	font-size: 24px;
	right: 15px;
	bottom: 17px;
	line-height: 0;
	opacity: 0;
	visibility: hidden;
	text-align: center;
	padding-top: 3px;
	background-color: #000;
	color: #fff;
	width: 34px;
	height: 34px;

	&.active {
		opacity: 1;
		visibility: visible;

		&:hover {
			background-color: $cl_main; } } }
