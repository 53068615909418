// NEWS
.news {
	&-index {
		&__list {
			border: 1px solid $cl_e1;
			border-bottom: none;
			display: flex;
			flex-wrap: wrap; }

		&__item {
			padding: 14*$rem 18*$rem;
			border-bottom: 1px solid $cl_e1;
			flex: 0 0 100%;
			max-width: 100%;
			display: flex;

			.img {
				flex: 0 0 7rem;
				max-width: 7rem;
				height: auto; }

			.content {
				padding-left: 1rem; }

			.date {
				color: $cl_main;
				font-size: 13*$rem;
				margin-bottom: 5*$rem; }

			.name {
				display: block;
				font-weight: bold;
				margin-bottom: 0.5rem;
				color: $cl_00; }

			.summary {
				display: -webkit-box;
				overflow: hidden;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				margin-bottom: 0.5rem;
				font-size: 14*$rem;
				line-height: 1.7;
				display: none; }

			.more {
				color: $cl_main;
				font-weight: bold; }

			&:hover {
				.name,
				.more {
					color: $cl_red; }

				.more {
					text-decoration: underline; } }

			@include media(sm, emp, 0) {
				flex: 0 0 50%;
				max-width: 50%;

				&:nth-child(odd) {
					border-right: 1px solid $cl_e1; }

				.img {
					flex: 0 0 170*$rem;
					max-width: 170*$rem;
					height: 150*$rem; }

				.summary {
					display: block; } } } }

	&-related {
		margin-top: 1.5rem;

		.about-item.style2 {
			&:last-child {
				padding-bottom: 0;
				margin-bottom: 0;
				border-bottom: none; } } } }
