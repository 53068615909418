// BUTTON STYLES
.btn {
	&-main {
		color: $cl_white;
		background-color: $cl_main;
		line-height: 1;
		padding: 0.8rem 2rem;
		// border-radius: 0.5rem

		.fa {
			margin-left: 0.5rem; }

		&:hover {
			filter: brightness(110%); } }

	&-cyan {
		color: $cl_white;
		background-color: $cl_cyanl;
		padding: 0.5rem;
		border-radius: 0.25rem;

		&:hover {
			filter: brightness(110%); } }

	&-blue {
		color: $cl_white;
		background-color: $cl_blue;
		line-height: 1.2;
		padding: 0.8rem 2rem;
		// border-radius: 0.5rem

		.fa {
			margin-left: 0.5rem; }

		&:hover {
			border-radius: 0.5rem;
			color: $cl_white;
			background-color: rgba($cl_blue, .8); } }

	&-black {
		&-trans {
			background-color: rgba($cl_00,.75);
			border: 1px solid $cl_00;
			padding: 12px 37px;
			color: $cl_white;
			font-weight: 600;

			&:hover {
				background-color: $cl_white;
				border-radius: 6px;
				transition: border-radius 250ms linear 150ms; } } }

	&-red {
		color: $cl_white;
		background-color: $cl_red;
		padding: 0.5rem 1rem;
		border-radius: 0.3rem;
		border: 1px solid $cl_red;
		line-height: 1;

		.zmdi {
			margin-right: 0.5rem; }

		&:hover {
			color: $cl_red;
			background-color: $cl_white; } }

	&-white {
		color: $cl_33;
		background-color: $cl_white;
		padding: 0.8rem 3rem;

		&:hover {
			color: $cl_white;
			background-color: $cl_33;
			border-radius: 6px;
			transition: border-radius 250ms linear 150ms; }

		&-trans {
			color: $cl_33;
			font-weight: 600;
			border: 1px solid;
			padding: 0.8rem 3rem;
			display: inline-block;

			&:hover {
				color: $cl_white;
				background-color: $cl_33;
				border-radius: 6px;
				transition: border-radius 250ms linear 150ms; } } }

	&-gray {
		color: $cl_00;
		background-color: $cl_f0;
		padding: 0.5rem;
		border-radius: 0.25rem;
		border: 1px solid $cl_ee;

		&:hover {
			filter: brightness(95%); } }

	&-reset {
		text-decoration: underline; } }
