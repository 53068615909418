// CONTACT STYLES
.contact {
	&-mapbox {
		height: 400px;
		width: 100%;
		position: relative;
		margin-bottom: 30*$rem;

		iframe {
			@include align(cen);
			width: 100%;
			height: 100%; } }

	&-info {
		&__row {
			display: flex;
			align-items: flex-start;
			margin-bottom: 1rem;

			.caption {
				flex: 0 0 110*$rem;
				max-width: 110*$rem;
				font-weight: bold; }

			.text {
				width: 100%; } } }

	&-title {
		margin-bottom: 30*$rem;

		&.black {
			color: $cl_00; } } }
