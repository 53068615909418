// PAGINATION
.paginationk {
	display: flex;
	flex-wrap: wrap;
	margin-top: 30*$rem;

	&.center {
		justify-content: center;

		.paginationk-list {
			justify-content: center; } }

	&.right {
		justify-content: flex-end;

		.paginationk-list {
			justify-content: flex-end; } }

	&-list {
		display: flex;
		flex-wrap: wrap;

		.item {
			&:not(:last-child) {
				margin-right: 6*$rem; }

			&:not(.dots) {
				.link {
					display: flex;
					height: 28*$rem;
					width: 28*$rem;
					overflow: hidden;
					justify-content: center;
					align-items: center;
					border: 1px solid $cl_e5;
					color: $cl_b2;
					font-size: 13*$rem;
					line-height: 1; }

				&.active,
				&:hover {
					.link {
						color: $cl_white;
						background-color: $cl_main;
						border-color: $cl_main; } } }

			&.disabled {
				display: none;
				// opacity: 0.5

				.link {
					cursor: default;
					pointer-events: none; }

				&:hover {
					.link {
						color: $cl_22;
						background-color: transparent; } } }

			&:first-child,
			&:last-child {
				.link {
					&:before {
						font-family: $ficon; } } }

			&:first-child {
				.link {
					&:before {
						content: "\f104"; } } }

			&:last-child {
				.link {
					flex-direction: row-reverse;

					&:before {
						content: "\f105"; } } }

			// +media(sm, emp, -1)
			//  display: none

			//  &:first-child,
			//  &:last-child
 }			//    display: block

		// +media(xs, emp, 0)
		// 	.item
		// 		.link
		// 			height: 36*$rem
		// 			width: 36*$rem
 } }		// 			font-size: 18*$rem
