// FILTER STYLES
.filter {
	background-color: $cl_66;
	padding: 1.25rem 0;

	&-search {
		width: 100%;
		height: 2.5rem;
		display: flex;
		border: 1px solid $cl_ed;
		margin-bottom: 1rem;

		.input {
			width: 100%;
			height: 100%;
			background-color: $cl_ed;
			border: none;
			padding: 0.5rem 0.75rem; }

		.button {
			height: 100%;
			flex: 0 0 2.5rem;
			max-width: 2.5rem;
			background-color: $cl_66;
			color: $cl_white;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.5rem;
			line-height: 1; }

		@include media(sm, emp, 0) {
			flex: 0 0 410*$rem;
			max-width: 410*$rem;
			width: auto;
			margin-bottom: 0; } }

	&-container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-left: auto;

		.selectbox {
			margin-left: auto;
			font-size: 14*$rem; }

		.btn-collapse {
			display: none; }

		.button {
			transition: all 250ms;
			background-color: $cl_white;
			padding: 0 20*$rem;
			white-space: nowrap;
			border-radius: 2px;
			height: 32*$rem;
			line-height: 32*$rem;
			border: 1px solid;

			&-black {
				color: $cl_main;

				&:hover {
					background-color: $cl_main; } }

			&-red {
				color: $cl_red;

				&:hover {
					background-color: $cl_red; } }

			&:hover {
				color: $cl_white; } }

		@include media(lg, emp, 0) {
			.selectbox {
				font-size: 1rem;
				margin-bottom: 0.5rem; }

			.btn-collapse {
				display: flex;
				align-items: center;
				color: $cl_33;
				background-color: $cl_ed;
				padding: 0.5rem 0.75rem;
				font-size: 14*$rem;

				&:after {
					content: "\f2f9";
					font-family: $ficon;
					line-height: 1;
					font-size: 20px;
					margin-left: 0.5rem;
					transform: rotate(-90deg);
					transition: transform 250ms; }

				&.collapsed {
					&:after {
						transform: rotate(0); } } }

			.button {
				&:not(:last-child) {
					margin-right: 0.5rem; } } }

		@include media(lg, emp, -1) {
			.selectbox {
				height: 2rem;
				border-color: $cl_main;
				background-color: $cl_white;
				border-radius: 4*$rem; } } }

	&-wrap {
		@include media(lg, emp, -1) {
			position: fixed;
			top: 0;
			right: 0;
			height: 100%;
			width: 100%;
			max-width: 360*$rem;
			z-index: 9;
			transform: rotateY(90deg);
			transform-origin: top right;
			transition: transform 350ms;
			background-color: $cl_white;

			&.opened {
				transform: rotateY(0); } } }

	&-list {
		@include media(lg, emp, 0) {
			display: flex;
			align-items: center; }

		@include media(lg, emp, -1) {
			overflow-y: auto;
			margin-top: 2.5rem;
			margin-bottom: 4rem;
			height: calc(100% - 6.5rem); } }

	&-item {
		position: relative;
		// margin-bottom: 0.5rem

		@include media(lg, emp, 0) {
			&:not(:last-child) {
				margin-right: 0.5rem; }

			&:nth-last-child(-n + 3) {
				.filter-collapse {
					left: auto;
					right: 0; } } } }

	&-collapse {
		font-size: 14*$rem;

		.inner {
			padding: 20*$rem; }

		.title {
			margin-bottom: 1rem;
			white-space: nowrap;
			font-weight: 600; }

		.list {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.item {
				flex: 0 0 49%;
				max-width: 49%; } }

		.ctrl {
			display: none; }

		.column {
			&:not(:last-child) {
				margin-bottom: 1.25rem; } }

		&.style2 {
			.inner {
				padding: 0; }

			.column {
				padding: 1.25rem; }

			.ctrl {
				padding: 1.25rem; } }

		@include media(lg, emp, 0) {
			position: absolute;
			top: 100%;
			left: 0;
			width: 360*$rem;
			background-color: $cl_white;
			z-index: 3;
			box-shadow: 0 3px 6px rgba(0,0,0,.2);

			&.style2 {
				width: 720*$rem;

				.inner {
					display: flex;
					flex-wrap: wrap; }

				.column {
					flex: 0 0 50%;
					max-width: 50%;
					padding: 1.25rem;
					border-bottom: 1px solid $cl_ed;

					&:nth-child(odd) {
						border-right: 1px solid $cl_ed; } }

				.ctrl {
					flex: 0 0 100%;
					max-width: 100%;
					border-top: none;
					margin-top: 0; } }

			.ctrl {
				padding-top: 20*$rem;
				margin-top: 15*$rem;
				border-top: 1px solid $cl_ed;
				text-align: right;
				display: block; } }

		@include media(lg, emp, -1) {
			display: block; } }

	&-open {
		height: 2rem;
		font-size: 14*$rem;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 1.25rem;
		color: $cl_main;
		background-color: $cl_white;
		border: 1px solid $cl_main;
		border-radius: 4*$rem;

		.zmdi {
			margin-right: 0.5rem; }

		@include media(lg, emp, 0) {
			display: none; } }

	&-close {
		width: 100%;
		height: 2.5rem;
		display: flex;
		align-items: center;
		padding: 0 1rem;
		color: $cl_white;
		background-color: $cl_main;
		position: fixed;
		left: 0;
		top: 0;

		.zmdi {
			margin-right: 0.5rem;
			font-size: 1.5rem; }

		@include media(lg, emp, 0) {
			display: none; } }

	&-ctrl {
		position: fixed;
		bottom: 0;
		left: 0;
		height: 4rem;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		background-color: $cl_white;
		box-shadow: 0 0 6px rgba(0,0,0,.25);

		.button {
			width: 40%;
			margin: 0 5%; }

		@include media(lg, emp, 0) {
			display: none; } }

	&-tags {
		position: sticky;
		top: 0;
		z-index: 1;
		width: 100%;
		padding: 0.5rem 0;
		background-color: $cl_white;

		.item {
			font-size: 14*$rem;
			padding: 0.25rem 0.5rem;
			color: $cl_white;
			background-color: $cl_main;
			margin-bottom: 0.25rem;
			margin-right: 0.25rem;

			&:empty {
				display: none; }

			&:after {
				content: "\f136";
				font-family: $ficon;
				margin-left: 0.5rem; }

			&:hover {
				background-color: $cl_red; } }

		@include media(sm, emp, 0) {
			padding: 0.5rem; }

		@include media(md, emp, 0) {
			padding: 0.5rem 0.5rem 0.5rem 1rem; } }

	.container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap; } }
