// TAB PANEL
.tab {
	&-nav {
		border: 1px solid $cl_ee;

		.list {
			display: flex;
			white-space: nowrap;
			overflow-x: auto; }

		.item {
			&:not(:last-child) {
				.link {
					&:before {
						content: "|";
						line-height: 1;
						color: $cl_ee;
						@include align(ver);
						right: 0; } } } }

		.link {
			padding: 14*$rem 40*$rem;
			display: block;
			width: 100%;
			color: $cl_00;
			font-size: 14*$rem;
			font-weight: bold;
			line-height: 1;
			position: relative;
			text-transform: capitalize;
			min-width: 150*$rem;

			&.active {
				color: #fff;
				background-color: $cl_main; }

			&:hover,
			&.active {
				&:before {
					display: none; } } }

		&.ver {
			padding: 0;

			.item {
				&:not(:last-child) {
					margin-right: 0;
					margin-bottom: 16px; } }

			.link {
				padding: 15px; } }

		&.center {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center; }

		@include media(md, emp, 0) {
			.link {
				&:hover {
					color: #fff;
					background-color: $cl_main; } } } }

	&-panel {
		visibility: hidden;
		height: 0;
		overflow: hidden;

		&.fade {
			opacity: 0;
			transition: 500ms; }

		&.active {
			visibility: visible;
			height: auto;
			opacity: 1; } } }
