// ABOUT STYLES
.about {
	&-video {
		@include img_box(636, 358);
		display: block;
		margin-left: auto;
		margin-right: auto;
		max-width: 636*$rem;

		iframe {
			width: 100%;
			height: 100%; } }

	&-item {
		display: flex;
		align-items: flex-start;
		flex-direction: column;

		&:not(:last-child) {
			padding-bottom: 30*$rem;
			margin-bottom: 30*$rem;
			border-bottom: 1px solid $cl_ee; }

		.img {
			margin-left: auto;
			margin-right: auto; }

		.title {
			font-weight: bold;
			margin-bottom: 1.5rem;
			font-size: 15*$rem;
			padding-top: 0.25rem; }

		.summary {
			font-size: 14*$rem;
			line-height: 1.7;
			margin-bottom: 0;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			overflow: hidden; }

		&.style2 {
			&:last-child {
				padding-bottom: 30*$rem;
				margin-bottom: 30*$rem;
				border-bottom: 1px solid $cl_ee; }

			.summary {
				-webkit-line-clamp: 4; }

			.title {
				padding-top: 0.5rem; } }

		@include media(xs, emp, 0) {
			flex-direction: row;

			.img {
				margin: 0;
				flex: 0 0 270*$rem;
				max-width: 270*$rem; }

			.content {
				padding-left: 30*$rem; } } }

	.img {
		margin-bottom: 30*$rem; }

	p {
		margin-bottom: 35*$rem; }

	.title {
		&-lg,
		&-sm {
			font-weight: bold;
			color: $cl_main;
			margin-bottom: 1.5rem;
			font-size: 15*$rem; }

		&-lg {
			text-transform: uppercase; }

		&-sm {
			text-decoration: underline; }

		&-video {
			color: $cl_red;
			text-align: center;
			margin-bottom: 1.5rem;
			font-size: 15*$rem;
			font-weight: bold; } }

	@include media(sm, emp, 0) {
		.title-video {
			margin-top: 75*$rem; } } }
