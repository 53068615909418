.img_ {
	&cen {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); }

	&full {
		width: 100%;
		height: 100%; }

	&fit {
		object-fit: cover; } }

.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;

	&:before {
		content: "";
		display: block; } }
