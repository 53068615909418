/*!
 * Title: Main style
 * Description: The main style
 * Author: Công ty TNHH TM-DV Thế Giới Web - thegioiwebs.net
 * Version: 1.0
 */

@import "tools";
@import "settings";
@import "generic";
@import "elements";
@import "objects";
@import "components";

// PAGES'S STYLE
@import "pages";
