// FOOTER
.footer {
	margin-top: auto;
	background-color: $cl_f5;
	padding-top: 2.25rem;
	overflow: hidden;

	&-bot {
		color: $cl_white;
		background: url(../img/root/bg-footer.png) no-repeat center / cover;

		.container {
			min-height: 50*$rem;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center; }

		@include media(xs, emp, 0) {
			.container {
				justify-content: space-between; } } }

	&-title {
		font-size: 18*$rem;
		font-weight: bold;
		margin-bottom: 1.5rem;
		user-select: none;

		@include media(md, emp, 0) {
			button {
				pointer-events: none; } } }

	&-menu {
		margin-bottom: 1.5rem;

		.item {
			margin-bottom: 10*$rem; }

		.link {
			display: flex;
			font-size: 15*$rem;

			&:before {
				content: "\f101";
				font-family: $ficon;
				flex: 0 0 18*$rem;
				max-width: 18*$rem; }

			&.tel {
				margin-top: 10*$rem;
				font-weight: bold;

				&:before {
					content: ""; } } }

		@include media(md, emp, 0) {
			margin-bottom: 0; } }

	&-info {
		margin-bottom: 1.5rem;

		.item {
			display: flex;
			align-items: baseline;
			margin-bottom: 10*$rem; }

		.text {
			margin-bottom: 0; }

		.fa {
			flex: 0 0 1.5rem;
			max-width: 1.5rem;
			height: 1.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			border: 1px solid $cl_main;
			margin-right: 10*$rem;
			color: $cl_main;
			font-size: 14*$rem;

			&-bank {
				font-size: 11*$rem; }

			&-envelope,
			&-chrome {
				font-size: 12*$rem; } }

		@include media(md, emp, 0) {
			margin-bottom: 0; } }

	&-block {
		~.footer-block {
			margin-top: auto; }

		.collapse-btn {
			&:after {
				content: "\f107";
				font: 1rem $ficon;
				margin-left: 0.5rem;
				display: inline-block;
				transition: transform 250ms; }

			&.collapsed {
				&:after {
					transform: rotate(-90deg); } } }

		@include media(md, emp, 0) {
			.collapse {
				display: block;

				&d {
					&:after {
						content: ""; } } } } }

	&-col {
		display: flex;
		flex-direction: column;

		.img-haveRegistered {
			margin-top: 1.5rem;
			margin-bottom: 2rem; }

		@include media(md, emp, 0) {
			margin-bottom: 54*$rem;

			.img-haveRegistered {
				margin-top: 2rem;
				margin-bottom: 0; } } }

	.copyright {
		margin-bottom: 0;
		text-align: center; }

	.social-list {
		margin-bottom: 0.5rem;

		.link {
			height: 26*$rem;
			width: 26*$rem;
			line-height: 1;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1rem;
			color: $cl_main;
			background-color: $cl_white;
			border: 1px solid $cl_green;

			&:hover {
				color: $cl_white;
				background-color: $cl_green;
				border-color: $cl_white; } } }

	@include media(xs, emp, 0) {
		.social-list {
			margin-bottom: 0; }

		.copyright {
			text-align: right; } } }
