// RATING STARS
.rating {
	position: relative;
	font-size: 12px;

	.numb {
		// color: $cl_gray2
		font-size: 14px; }

	.rating-disabled .rating-stars {
		cursor: default !important; }

	.rating-md {
		font-size: unset !important; }

	.rating-container {
		display: inline-block;

		.empty-stars {
			color: $cl_orange; } }

	&.without-caption {
		.theme-krajee-uni .star {
			font-size: 20px;
			margin: 0 1px;
			line-height: 1; }

		.rating-container {
			.clear-rating,
			.caption {
				display: none; }


			.filled-stars {
				-webkit-text-stroke: 0;
				text-shadow: none;
				color: $cl_orange; } } } }
