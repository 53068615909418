// COLORS
$cl_white: #fff;
$cl_brown: #9b9b9b;
$cl_gray: #edeff1;
$cl_gray_white: rgba($cl_gray,.5);
$cl_yellow: #eae04e;
$cl_red: #f00;
$cl_redl: #f7082a;
$cl_blue: #3d3878;
$cl_cyan: #428bca;
$cl_cyand: #009688;
$cl_cyanl: #4db3d6;
$cl_green: #098d00;
$cl_greenl: #00a63f;
$cl_orange: #ff5722;
$cl_orange2: #d3962b;
$cl_purple: #c097e0;
$cl_dark: #121b22;

$cl_fa: #fafafa;
$cl_f6: #f6f6f6;
$cl_f5: #f5f5f5;
$cl_f0: #f0f0f0;
$cl_ee: #eee;
$cl_ed: #ededed;
$cl_eb: #ebebeb;
$cl_e7: #e7e7e7;
$cl_e6: #e6e6e6;
$cl_e5: #e5e5e5;
$cl_e1: #e1e1e1;
$cl_b3: #b3b3b3;
$cl_b2: #b2b2b2;
$cl_a5: #a5a5a5;
$cl_99: #999;
$cl_8f: #8f8f8f;
$cl_83: #838383;
$cl_7e: #7e7e7e;
$cl_77: #777;
$cl_73: #737373;
$cl_66: #666;
$cl_44: #444;
$cl_40: #404040;
$cl_33: #333;
$cl_2d: #2d2d2d;
$cl_2a: #2a2a2a;
$cl_22: #222;
$cl_11: #111;
$cl_00: #000;

$cl_main: $cl_green;
$cl_footer_bg: #2e3a49;
$cl_nav_active: $cl_white;
$cl_fb: #4b5d93;
$cl_tt: #3caaf6;
$cl_gg: #d0463b;
$cl_bg_modal: #ebf7ea;

.cl {
	&_fb {
		color: $cl_fb; }
	&_tt {
		color: $cl_tt; }
	&_gg {
		color: $cl_gg; }
	&_main {
		color: $cl_main; }
	&_white {
		color: $cl_white; }
	&_blue {
		color: $cl_blue; }
	&_red {
		color: $cl_red; }
	&_brown {
		color: $cl_brown; }
	&_gray {
		color: $cl_gray; }
	&_orange {
		color: $cl_orange; }
	&_cyanl {
		color: $cl_cyanl; }
	&_99 {
		color: $cl_99; }
	&_77 {
		color: $cl_77; }
	&_22 {
		color: $cl_22; } }

.text {
	&-red {
		color: #f00; } }

.bg {
	&-gray {
		background-color: $cl_gray;
		&-white {
			background-color: $cl_gray_white; } }
	&-green {
		background-color: $cl_green; }
	&-fa {
		background-color: $cl_fa; }
	&-main {
		background-color: $cl_main; } }
