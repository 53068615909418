// HOME STYLES

// SOCIALS
.social {
	&-list {
		display: flex;
		align-items: center;

		.item {
			&:not(:last-child) {
				margin-right: 1.25rem; } } }

	&-share {
		background-color: $cl_f6;
		border: 1px solid $cl_e1;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 12*$rem 20*$rem 0.5rem;
		min-height: 50*$rem;
		border-radius: 0.5rem;

		.text {
			font-weight: bold;
			font-size: 13*$rem;
			color: $cl_7e;
			margin-right: 0.5rem; }

		.list {
			display: flex;
			align-items: center;
			font-size: 1.25rem;

			.item {
				margin-bottom: 0.25rem;

				&:not(:last-child) {
					margin-right: 0.5rem; } } }

		.download {
			display: flex;
			height: 25*$rem;
			background-color: $cl_red;
			border-radius: 0.25rem;
			flex: 0 0 97*$rem;
			max-width: 97*$rem;
			margin-right: auto;
			color: $cl_white;

			.cap {
				flex: 0 0 72*$rem;
				max-width: 72*$rem;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 13*$rem; }

			.fa {
				height: 100%;
				flex: 0 0 25*$rem;
				max-width: 25*$rem;
				background-color: $cl_00;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;

				&:after {
					content: "";
					@include align(ver);
					right: -2px;
					height: 5*$rem;
					width: 5*$rem;
					background-color: $cl_00;
					transform: rotate(45deg);
					display: inline-block; } } }

		&.right {
			justify-content: flex-end; } } }

// MAIN
.main {
	padding-top: 2rem;
	padding-bottom: 2rem; }

// SECTION
.section {
	&:not(:last-child) {
		margin-bottom: 30*$rem; } }

// SUPPORT
.hasShadow {
	box-shadow: 0 0 0.5rem $cl_ee; }

.contact {
	&-box {
		background-color: $cl_fa;
		text-align: center;
		margin: 1.25rem 1.25rem 0 1.25rem;
		padding: 18*$rem;

		.name {
			color: $cl_main;
			font-size: 15*$rem;
			font-weight: bold;
			margin-bottom: 10*$rem; }

		.phone {
			color: $cl_red;
			font-size: 15*$rem;
			font-weight: bold;
			margin-bottom: 15*$rem;
			display: block; } }

	&-app {
		display: flex;
		align-items: center;
		justify-content: center;

		.item {
			&:not(:last-child) {
				margin-right: 12*$rem; } } } }

.support {
	padding-bottom: 1.25rem;

	img {
		&+.contact-box {
			margin-top: 12*$rem; } }

	.title {
		font: 30*$rem $fs;
		color: $cl_white;
		background-color: $cl_main;
		text-transform: uppercase;
		padding: 10*$rem;
		line-height: 1;
		text-align: center; } }

// PARTNERS
.partner {
	background-color: $cl_eb;
	min-height: 87*$rem;
	display: flex;
	align-items: center;

	&-slider {
		padding-left: 70*$rem;
		padding-right: 70*$rem;
		flex: 0 0 100%;
		max-width: 100%;

		.item {
			opacity: 0.6;
			transition: opacity 250ms;

			&:hover {
				opacity: 1; } } } }

// DETAIL
.detail {
	font-size: 14*$rem;
	line-height: 1.7;

	.img {
		margin-bottom: 1rem; }

	p {
		margin-bottom: 35*$rem; }

	.social-share {
		@include media(sm, emp, 0) {
			margin-top: 75*$rem; } } }

// SERVICES
.service-top {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;

	.img {
		margin-bottom: 1rem; }

	@include media(sm, emp, 0) {
		flex-wrap: nowrap;

		.text {
			padding-right: 30*$rem; }

		.img {
			flex: 0 0 370*$rem;
			max-width: 370*$rem; } } }

.captcha {
	flex: 0 0 90*$rem;
	max-width: 90*$rem;
	color: $cl_00;
	background-color: $cl_f0;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	margin-bottom: 0;
	font-weight: bold;
	font-size: 14*$rem;
	border-radius: 0 0.25rem 0.25rem 0;
	border: 1px solid $cl_e1;
	border-left: none;

	&-box {
		display: flex;

		.form-control {
			border-radius: 0.25rem 0 0 0.25rem; } } }

.fa {
	&-remove {
		&-black {
			background: url(../img/root/icon-remove-black.png) no-repeat center / cover;
			height: 10*$rem;
			width: 10*$rem;
			display: inline-block;

			&:hover {
				background-image: url(../img/root/icon-remove-red.png); } }

		&-red {
			background: url(../img/root/icon-remove-red.png) no-repeat center / cover;
			height: 10*$rem;
			width: 10*$rem;
			display: inline-block; } } }
