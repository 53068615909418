// ***** IMAGE BOX
@mixin img_box ($w, $h) {
	&:before {
		padding-top: percentage($h / $w); }

	img {
		@include align(cen); } }

//==============================================================================
//==============================================================================

// ***** ARROW
@mixin arrow($dir, $size, $color) {
	height: 0;
	width: 0;
	border-style: solid;
	border-width: $size;
	border-color: transparent;
	display: block;

	@if $dir == "top" {
		border-top: 0;
		border-bottom-color: $color; }
	@else if $dir == "right" {
		border-right: 0;
		border-left-color: $color; }
	@else if $dir == "bottom" {
		border-bottom: 0;
		border-top-color: $color; }
	@else if $dir == "left" {
		border-left: 0;
		border-right-color: $color; } }
// Ex.
//.a
//	+arrow(top, 5px, red)

//==============================================================================
//==============================================================================

@mixin clear() {
	&:after {
		content: "";
		display: table;
		clear: both; } }

@mixin align($dir) {
	position: absolute;

	@if $dir == "cen" {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); }
	@else if $dir == "ver" {
		top: 50%;
		transform: translateY(-50%); }
	@else if $dir == "hor" {
		left: 50%;
		transform: translateX(-50%); }

	@else if $dir == "hor-left" {
		left: 0;
		top: 50%;
		transform: translateY(-50%); }

	@else if $dir == "hor-right" {
		right: 0;
		top: 50%;
		transform: translateY(-50%); }

	@else if $dir == "ver-top" {
		left: 50%;
		top: 0;
		transform: translateX(-50%); }

	@else if $dir == "ver-bot" {
		left: 50%;
		bottom: 0;
		transform: translateX(-50%); } }

//==============================================================================
//==============================================================================

@mixin nowrap {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis; }

//==============================================================================
//==============================================================================

@mixin obfit {
	height: 100%;
	width: 100%;
	object-fit: cover;
	object-position: center; }

//==============================================================================
//==============================================================================

@mixin bgcover {
	background: {
		repeat: no-repeat;
		position: center center;
		size: cover; } }

//==============================================================================
//==============================================================================

@mixin text($fsz, $lh, $r) {
	font-size: $fsz;
	line-height: $lh;
	max-height: #{($fsz * $lh * $r)};
	overflow: hidden; }
