// HEADER
.header {
	padding-top: 0.5rem;

	@include media(sm, emp, 0) {
		padding-top: 1.25rem; }

	&-top {
		display: flex;
		padding-bottom: 1rem;
		flex-wrap: wrap;

		@include media(sm, emp, 0) {
			flex-wrap: nowrap;
			align-items: center;
			justify-content: space-between; } }

	&-bot {
		.title {
			height: 2.5rem;
			color: $cl_white;
			background-color: $cl_main;
			font-size: 14*$rem;
			font-weight: bold;
			display: block;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 1rem;

			&:before {
				content: "\f009";
				font-family: $ficon;
				margin-right: 0.5rem;
				display: inline-block;
				font-weight: 400; } }

		.list {
			height: 100%;
			overflow-x: auto;
			display: flex;
			align-items: center;
			border: 1px solid $cl_main;
			padding: 0.5rem 1rem;

			.item {
				&:not(:last-child) {
					&:after {
						content: "|";
						line-height: 1;
						color: $cl_e1;
						margin-left: 30*$rem;
						margin-right: 30*$rem; } } }

			.link {
				font-weight: bold;
				font-size: 14*$rem; } }

		@include media(sm, emp, 0) {
			border: 1px solid $cl_main;

			.title {
				height: 52*$rem;
				margin-bottom: 0;

				&:before {
					content: ""; } }

			.list {
				border: none;
				padding: 0; } }

		@include media(lg, emp, 0) {
			.title {
				cursor: pointer; } } }

	&-logo {
		flex: 0 0 12.5rem;
		max-width: 12.5rem;
		margin: 0 auto 1rem;

		@include media(sm, emp, 0) {
			margin: 0; }

		@include media(lg, emp, 0) {
			flex: 0 0 284*$rem;
			max-width: 284*$rem; } }

	&-right {
		flex: 0 0 100%;
		max-width: 100%;

		.link {
			display: inline-block; }

		@include media(sm, emp, 0) {
			flex: 0 0 32rem;
			max-width: 32rem; }

		@include media(md, emp, 0) {
			flex: 0 0 47.5rem;
			max-width: 47.5rem; }

		@include media(lg, emp, 0) {
			flex: 0 0 800*$rem;
			max-width: 800*$rem; } }

	&-contact {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 1.25rem;

		.fa {
			margin-right: 0.5rem; }

		.item {
			.link {
				&:not(:last-child) {
					margin-bottom: 0.5rem; } } }

		.contact-group {
			font-size: 1rem;
			font-weight: bold;
			color: $cl_red;

			.fa {
				color: $cl_main;

				&-phone-in-talk {
					height: 17*$rem;
					width: 17*$rem;
					background: url(../img/root/icon-phone-in-talk.png) no-repeat center; } } }

		@include media(xs, emp, 0) {
			.item {
				.link {
					&:not(:last-child) {
						margin-bottom: 0;

						&:after {
							content: "|";
							line-height: 1;
							color: $cl_e1;
							margin-left: 0.75rem;
							margin-right: 0.75rem; } } } } }

		@include media(md, emp, 0) {
			.item {
				.link {
					&:not(:last-child) {
						&:after {
							margin-left: 1.25rem;
							margin-right: 1.25rem; } } } } } }

	&-search {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.form-search {
			height: 2.5rem;
			width: 100%; }

		.cart {
			display: flex;
			align-items: flex-start;
			margin-left: 1rem;

			&-icon {
				height: 2.5rem;
				width: 2.5rem;
				margin-right: 0;
				background-color: $cl_main;
				position: relative;
				border-radius: 0.375rem;
				background: $cl_main url(../img/root/icon-shopping-bag.png) no-repeat center;

				.quantity {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 1.5rem;
					width: 1.5rem;
					font-size: 14*$rem;
					color: $cl_main;
					background-color: $cl_white;
					border: 1px solid $cl_main;
					border-radius: 50%;
					overflow: hidden;
					position: absolute;
					top: -0.625rem;
					right: -0.625rem;
					font-weight: bold; } }

			&-text {
				font-size: 0;

				span {
					display: block;

					&:not(:last-child) {
						margin-bottom: 6*$rem; }

					&.fb {
						font-weight: bold; } } } }

		@include media(md, emp, 0) {
			.form-search {
				flex: 0 0 530*$rem;
				max-width: 530*$rem;
				width: auto; }

			.cart {
				margin-left: 0;

				&-icon {
					margin-right: 1.5rem; }

				&-text {
					font-size: 15*$rem; } } } }

	@include media(xs, emp, -1) {
		.log {
			text-align: right; } } }
