// ASIDE
.aside {
	&-block {
		&:not(:last-child) {
			margin-bottom: 30*$rem; }

		img {
			display: block;
			margin-left: auto;
			margin-right: auto; } }

	&-nav {
		border: 1px solid $cl_ee;

		.title {
			color: $cl_main;
			text-transform: uppercase;
			font-weight: bold;
			font-size: 14*$rem;
			padding: 10*$rem 25*$rem;
			background-color: $cl_f0;
			border-bottom: 1px solid $cl_ee;
			margin-bottom: 0;
			line-height: 1.5;
			display: flex;

			&:before {
				content: "\f196";
				font-family: $ficon;
				font-weight: normal;
				flex: 0 0 1.25rem;
				max-width: 1.25rem; }

			&.style2 {
				color: $cl_00;

				&:before {
					content: "\f0c9"; } } }

		&__list {
			font-size: 14*$rem;
			color: $cl_2a;
			padding: 15*$rem 10*$rem 15*$rem 25*$rem;

			>.item {
				.collapse {
					&-title {
						display: flex;
						align-items: baseline;
						font-weight: bold;
						margin-bottom: 10*$rem;

						&:hover {
							color: $cl_main; } }

					&-btn {
						flex: 0 0 1rem;
						max-width: 1rem;
						font-size: 13*$rem;
						text-align: left;

						&:before {
							content: "\f105";
							font-family: $ficon;
							display: inline-block;
							transition: transform 250ms;
							transform: rotate(90deg); }

						&.collapsed {
							&:before {
								transform: rotate(0); } } } } }

			.list {
				padding-left: 40*$rem;

				.item {
					margin-bottom: 12*$rem; } } } }

	&-wrap {
		// position: sticky
 }		// top: 120*$rem

	&-open {
		padding: 0.5rem 1rem;
		color: $cl_white;
		background-color: $cl_main;
		border-radius: 4*$rem;
		display: block;
		font-size: 14*$rem;
		position: sticky;
		top: 105*$rem;
		z-index: 3;
		margin-left: auto; }

	&-close {
		display: flex;
		align-items: center;
		height: 2.5rem;
		width: 100%;
		padding-left: 0.8rem;
		padding-right: 0.8rem;
		color: $cl_white;
		background-color: $cl_main;
		position: absolute;
		top: 0;
		left: 0; }

	&-ads {
		.aside-wrap {
			display: flex;
			flex-wrap: wrap;
			margin-left: -0.75rem;
			margin-right: -0.75rem; }

		.aside-block {
			padding: 0;
			text-align: center;
			flex: 0 0 50%;
			max-width: 50%;
			padding-left: 0.75rem;
			padding-right: 0.75rem; }

		@include media(md, emp, 0) {
			.aside-block {
				flex: 0 0 100%;
				max-width: 100%; } } }

	@include media(md, emp, 0) {
		&-close,
		&-open {
			display: none; }

		&-ads {
			.aside-wrap {
				top: 5rem; } } }

	@include media(md, emp, -1) {
		position: fixed;
		top: 0;
		right: 0;
		height: 100%;
		width: 100%;
		max-width: 360*$rem;
		transform-origin: top right;
		transform: rotateY(90deg);
		transition: 500ms;
		padding: 0;
		z-index: 9;
		background-color: $cl_white;

		&.opened {
			transform: rotate(0); }

		&-wrap {
			height: calc(100% - 2.5rem);
			margin-top: 2.5rem;
			overflow-y: auto;
 } } }			// padding: 30px


.js-asideTrigger {
	@include media(md, emp, 0) {
		pointer-events: none; } }
