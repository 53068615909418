// SLIDER STYLES
.flickity-page-dots {
	bottom: 0;

	.dot {
		height: 9px;
		width: 9px;
		background-color: $cl_orange;
		border:  1px solid $cl_orange;
		box-shadow: none;
		opacity: 1;
		vertical-align: middle;

		&.is-selected {
			height: 13px;
			width: 13px;
			box-shadow: 0 0 0 1px #fff inset; } } }

.slick {
	&-dots {
		// bottom: 0
		margin-top: 1.25rem;
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;

		li {
			margin-left: 5px;
			margin-right: 5px; }

		button {
			@extend .reset-btn;
			height: 5*$rem;
			width: 1.5rem;
			background-color: rgba($cl_white, 0.6);
			box-shadow: none;
			opacity: 1;
			vertical-align: middle;
			font-size: 0;
			border-radius: 5*$rem; }

		.slick-active {
			button {
				background-color: $cl_main; } } }

	&-slider {
		overflow: hidden;

		&:hover {
			.slick-arrow {
				opacity: 1; } } }

	&-list {
		margin-left: -5*$rem;
		margin-right: -5*$rem;

		.item {
			padding-left: 5*$rem;
			padding-right: 5*$rem; } }

	&-track {
		display: flex;
		align-items: center; }

	&-sync {
		&-for {
			margin-bottom: 1rem; }

		&-nav {
			.item {
				.img {
					&:after {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						width: 100%;
						transition: 250ms;
						background-color: rgba(0,0,0,.6); } }

				&.slick-current {
					.img {
						&:after {
							opacity: 0; } } } } } }

	&-arrow {
		@extend .reset-btn;
		@include align(ver);
		display: flex;
		align-items: center;
		justify-content: center;
		height: 1.25rem;
		width: 1.25rem;
		font-size: 0;
		color: $cl_b3;
		border-radius: 50%;
		z-index: 1;
		// opacity: 0
		border: 1px solid $cl_b3;

		&:before {
			font: 12*$rem $ficon; } }

	&-prev {
		left: 1.25rem;

		&:before {
			content: "\f104"; } }

	&-next {
		right: 1.25rem;

		&:before {
			content: "\f105"; } } }
