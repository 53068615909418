// NAVIGATION
.navigation {
	@include media(md, emp, 0) {
		background: url(../img/root/bg-nav.png) no-repeat center / cover; }

	&-open {
		font-size: 18*$rem;
		line-height: 1;
		margin-left: 1rem;
		color: $cl_white;
		height: 2.5rem;
		width: 2.5rem;
		flex: 0 0 2.5rem;
		max-width: 2.5rem;
		align-items: center;
		justify-content: center;
		display: flex;
		background-color: $cl_main;
		border-radius: 0.4rem; }

	&-list {
		display: flex;
		flex-direction: column;

		.link {
			display: block;

			.fa-home {
				font-size: 18*$rem; }

			// &.active
 }			// 	color: $cl_red

		.social-list {
			font-size: 1.25rem; }

		.language {
			margin-bottom: 1rem;
			font-size: 15*$rem;

			img {
				display: inline-block;
				vertical-align: middle;
				margin-right: 0.5rem; }

			&-btn {
				line-height: 1; } } }

	&-close {
		display: flex;
		align-items: center;
		height: 2.5rem;
		width: 100%;
		padding-left: 15*$rem;
		padding-right: 15*$rem;
		color: $cl_white;
		background-color: $cl_main;
		position: absolute;
		top: 0;
		left: 0;

		.zmdi {
			font-size: 1.5rem;
			margin-right: 0.5rem; } }

	@include media(md, emp, 0) {
		color: $cl_white;

		&-list {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			min-height: 50*$rem;

			.item {
				&:last-child {
					display: flex;
					align-items: center; } } }

		.language {
			margin-bottom: 0; }

		.social-list {
			&:before {
				content: "|";
				display: inline-block;
				line-height: 1;
				margin-left: 1rem;
				margin-right: 1rem; } }

		&-close,
		&-open {
			display: none; } }

	@include media(md, emp, -1) {
		position: fixed;
		top: 0;
		right: 0;
		width: 100%;
		max-width: 360px;
		height: 100%;
		transition: transform 350ms;
		transform: rotateY(90deg);
		transform-origin: top right;
		box-shadow: 0 0 10px rgba($cl_33, .5);
		z-index: 9;
		// background-color: $cl_main
		background-color: rgba($cl_f5,1);

		&.opened {
			transform: rotateY(0); }

		&-wrap {
			height: calc(100% - 2.5rem);
			margin-top: 2.5rem;
			overflow-y: auto; }

		&-list {
			padding-top: 0.5rem;

			>.item {
				padding: 0.5rem 0; } }

		.drop-list {
			.item {
				&:first-child {
					margin-top: 0.5rem; }

				&:last-child {
					margin-bottom: 0.5rem; } } }

		.container {
			height: 100%; } } }
