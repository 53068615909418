@charset "UTF-8";
/*!
 * Title: Main style
 * Description: The main style
 * Author: Công ty TNHH TM-DV Thế Giới Web - thegioiwebs.net
 * Version: 1.0
 */
@keyframes transUp {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-2px); } }

.transUp {
  transition: all 350ms; }

.transUp:hover {
  animation: transUp 350ms ease-in-out 0s forwards; }

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(1rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-50px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(30px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes fadeMInDown {
  0% {
    opacity: 0;
    margin-top: -30px; }
  100% {
    opacity: 1;
    margin-top: 0; } }

@keyframes dropDown {
  0% {
    opacity: 0;
    transform: translateY(-30px); }
  60% {
    opacity: 1;
    transform: translateY(0); }
  80% {
    opacity: 1;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes dropUp {
  0% {
    opacity: 0;
    transform: scale(0); }
  60% {
    opacity: 1;
    transform: scale(1.15); }
  80% {
    opacity: 1;
    transform: scale(0.9); }
  100% {
    opacity: 1;
    transform: translateY(1); } }

@keyframes scaleIn {
  0% {
    transform: scale(0);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes scaleOut {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0);
    opacity: 0; } }

@keyframes scaleDown {
  0% {
    transform: scale(1.2, 1.2) translate(-50%, -50%); }
  100% {
    transform: scale(1, 1) translate(-50%, -50%); } }

@font-face {
  font-family: "UTM Bebas";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/UTM-Bebas/UTM-Bebas.ttf") format("truetype"); }

@font-face {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Roboto/Roboto-Medium.ttf") format("truetype"); }

.fr {
  font-family: inherit; }

.fb {
  font-family: inherit; }

.fsr {
  font-family: inherit; }

.fsb {
  font-family: inherit; }

.fm {
  font-family: inherit; }

.font-italic {
  font-style: italic; }

.text-underline {
  text-decoration: underline; }

.fw-6 {
  font-weight: 600; }

.fs_12 {
  font-size: 0.75rem; }

.fs_13 {
  font-size: 0.8125rem; }

.fs_14 {
  font-size: 0.875rem; }

.fs_15 {
  font-size: 0.9375rem; }

.fs_16 {
  font-size: 1rem; }

.fs_18 {
  font-size: 1rem; }

@media screen and (min-width: 1200px) {
  .fs_18 {
    font-size: 1.125rem; } }

.fs_20 {
  font-size: 1.125rem; }

@media screen and (min-width: 1200px) {
  .fs_20 {
    font-size: 1.25rem; } }

.fs_22 {
  font-size: 1.125rem; }

@media screen and (min-width: 1200px) {
  .fs_22 {
    font-size: 1.375rem; } }

.fs_24 {
  font-size: 1.125rem; }

@media screen and (min-width: 1200px) {
  .fs_24 {
    font-size: 1.5rem; } }

.fs_30 {
  font-size: 1.25rem; }

@media screen and (min-width: 1200px) {
  .fs_30 {
    font-size: 1.875rem; } }

.fs_32 {
  font-size: 1.5rem; }

@media screen and (min-width: 768px) {
  .fs_32 {
    font-size: 2rem; } }

.fs_35 {
  font-size: 1.5rem; }

@media screen and (min-width: 768px) {
  .fs_35 {
    font-size: 2.1875rem; } }

.fs_40 {
  font-size: 1.5rem; }

@media screen and (min-width: 768px) {
  .fs_40 {
    font-size: 2.5rem; } }

.fs_50 {
  font-size: 1.5rem; }

@media screen and (min-width: 768px) {
  .fs_50 {
    font-size: 3.125rem; } }

.fs_70 {
  font-size: 1.5rem; }

@media screen and (min-width: 768px) {
  .fs_70 {
    font-size: 4.375rem; } }

.cl_fb {
  color: #4b5d93; }

.cl_tt {
  color: #3caaf6; }

.cl_gg {
  color: #d0463b; }

.cl_main {
  color: #098d00; }

.cl_white {
  color: #fff; }

.cl_blue {
  color: #3d3878; }

.cl_red {
  color: #f00; }

.cl_brown {
  color: #9b9b9b; }

.cl_gray {
  color: #edeff1; }

.cl_orange {
  color: #ff5722; }

.cl_cyanl {
  color: #4db3d6; }

.cl_99 {
  color: #999; }

.cl_77 {
  color: #777; }

.cl_22 {
  color: #222; }

.text-red {
  color: #f00; }

.bg-gray {
  background-color: #edeff1; }

.bg-gray-white {
  background-color: rgba(237, 239, 241, 0.5); }

.bg-green {
  background-color: #098d00; }

.bg-fa {
  background-color: #fafafa; }

.bg-main {
  background-color: #098d00; }

.selectbox {
  position: relative;
  height: 2.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  border: 1px solid rgba(48, 51, 58, 0.1);
  border-radius: 0.25rem; }

.selectbox:after {
  content: "\f2f9";
  font-family: "FontAwesome";
  font-size: 1.25rem;
  margin-right: 0.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0; }

.selectbox select {
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  appearance: none;
  padding: 0 1.5rem 0 1rem;
  width: 100%;
  height: 100%;
  color: inherit; }

.checkbox {
  user-select: none;
  display: flex;
  align-items: baseline; }

.checkbox-input {
  display: none; }

.checkbox-input:checked ~ .checkbox-text:before {
  background-color: #333;
  font-size: 1rem; }

.checkbox-text {
  display: flex;
  width: 100%; }

.checkbox-text:before {
  content: "\f26b";
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-family: "FontAwesome";
  height: 1rem;
  flex: 0 0 1rem;
  max-width: 1rem;
  margin-right: 0.5rem;
  border-radius: 2px;
  border: 2px solid #333;
  font-size: 0;
  transition: all 150ms;
  color: #fff; }

.checkbox.radio .checkbox-text:before {
  border-radius: 50%; }

.form-control {
  border-color: #e1e1e1; }

.form-control:focus {
  border-color: #098d00; }

.form-search {
  display: flex; }

.form-search .input {
  height: 100%;
  width: 100%;
  border-radius: 0.375rem 0 0 0.375rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  color: #737373;
  font-size: 0.8125rem;
  border: 1px solid #e1e1e1; }

.form-search .button {
  height: 100%;
  width: 100%;
  max-width: 2.5rem;
  border-radius: 0 0.375rem 0.375rem 0;
  color: #fff;
  background-color: #098d00;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  font-size: 0; }

.form-search .button .fa {
  font-size: 1rem; }

@media screen and (min-width: 992px) {
  .form-search .button {
    font-size: 0.9375rem;
    max-width: 8.125rem; }
  .form-search .button .fa {
    margin-right: 1px; } }

.form-contact .row {
  margin-left: -5px;
  margin-right: -5px; }

.form-contact [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px; }

.form-contact__row {
  margin-bottom: 0.625rem; }

.form-contact .form-control {
  height: 2.5rem;
  border-color: #e1e1e1; }

.form-contact .form-control.txt {
  height: 11.875rem; }

.form-contact .btn-main {
  text-align: center;
  width: 100%;
  transition: filter 350ms;
  border-radius: 0.25rem;
  font-weight: bold; }

.form-contact .btn-main:hover {
  filter: brightness(110%); }

.form-request .row {
  margin-left: -6px;
  margin-right: -6px; }

.form-request [class*="col-"] {
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 1rem; }

.form-request .col-4,
.form-request .col-2 {
  flex: 0 0 100%;
  max-width: 100%; }

.form-request .form-control {
  font-size: 0.8125rem;
  height: 2.5rem; }

.form-request .btn-main {
  padding: 0;
  font-size: 0.875rem;
  font-weight: bold;
  height: 2.5rem;
  width: 100%;
  border-radius: 0.325rem; }

@media screen and (min-width: 576px) {
  .form-request .col-4 {
    flex: 0 0 33.333%;
    max-width: 33.333%; }
  .form-request .col-2 {
    flex: 0 0 33.333%;
    max-width: 33.333%; }
  .form-request .col-2:last-child {
    flex: 0 0 100%;
    max-width: 100%; } }

@media screen and (min-width: 768px) {
  .form-request .col-4 {
    flex: 0 0 31.8%;
    max-width: 31.8%; }
  .form-request .col-2 {
    flex: 0 0 18%;
    max-width: 18%; }
  .form-request .col-2:last-child {
    flex: 0 0 18%;
    max-width: 18%; } }

.search .tab-parent {
  display: flex; }

.search .tab-nav {
  flex: 8.125rem;
  max-width: 8.125rem; }

.search .tab-nav .button {
  text-align: center;
  height: 100%;
  width: 100%;
  font-size: 0.875rem;
  color: #fff;
  background-color: #098d00;
  line-height: 1; }

.search .tab-nav .button:after {
  vertical-align: middle; }

.search .tab-content {
  width: 100%; }

.searchbox {
  background-color: #f5f5f5;
  display: flex;
  width: 100%;
  height: 3.5rem;
  padding: 0.3rem 0.5rem; }

.searchbox .input {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 0.0625rem solid #e6e6e6; }

.searchbox .button {
  flex: 0 0 3.5rem;
  max-width: 3.5rem;
  color: #fff;
  background-color: #098d00;
  font-size: 0; }

.searchbox .button .zmdi {
  font-size: 1rem; }

@media screen and (min-width: 576px) {
  .searchbox .button {
    flex: 0 0 7.5rem;
    max-width: 7.5rem;
    font-size: 1rem; }
  .searchbox .button .zmdi {
    margin-right: 0.5rem; } }

.pt-10 {
  padding-top: 10px; }

.pt-15 {
  padding-top: 15px; }

.pt-20 {
  padding-top: 20px; }

.pt-70 {
  padding-top: 40px; }

@media screen and (min-width: 992px) {
  .pt-70 {
    padding-top: 50px; } }

@media screen and (min-width: 1200px) {
  .pt-70 {
    padding-top: 80px; } }

.pb-10 {
  padding-bottom: 10px; }

.pb-15 {
  padding-bottom: 15px; }

.pb-20 {
  padding-bottom: 20px; }

.pb-40 {
  padding-bottom: 30px; }

@media screen and (min-width: 992px) {
  .pb-40 {
    padding-bottom: 40px; } }

.pb-50 {
  padding-bottom: 30px; }

@media screen and (min-width: 992px) {
  .pb-50 {
    padding-bottom: 40px; } }

@media screen and (min-width: 1200px) {
  .pb-50 {
    padding-bottom: 50px; } }

.mt-30 {
  margin-top: 30px; }

.mt-40 {
  margin-top: 20px; }

@media screen and (min-width: 768px) {
  .mt-40 {
    margin-top: 30px; } }

@media screen and (min-width: 1200px) {
  .mt-40 {
    margin-top: 40px; } }

.mb-25 {
  margin-bottom: 15px; }

@media screen and (min-width: 992px) {
  .mb-25 {
    margin-bottom: 20px; } }

@media screen and (min-width: 1200px) {
  .mb-25 {
    margin-bottom: 25px; } }

.mb-30 {
  margin-bottom: 10px; }

@media screen and (min-width: 576px) {
  .mb-30 {
    margin-bottom: 30px; } }

button {
  cursor: pointer;
  transition: 250ms;
  font-weight: inherit;
  color: inherit; }

button:focus {
  outline: none; }

a {
  transition: all 250ms;
  cursor: pointer;
  color: inherit; }

a:hover, a:focus {
  text-decoration: none;
  color: inherit; }

* {
  outline: none; }

img {
  max-width: 100%;
  max-height: 100%; }

textarea {
  resize: none; }

.reset-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.reset-btn, .slick-dots button, .slick-arrow, .quantity-btn {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-block; }

.form-control:focus {
  box-shadow: none;
  border-color: unset; }

body {
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  font-size: 0.9375rem; }

body:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 350ms linear 20ms;
  z-index: 5; }

body.nav-opened {
  overflow: hidden; }

body.nav-opened:before {
  width: 100%; }

body.nav-opened .header:before {
  width: 100%; }

.container {
  max-width: 100%; }

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1200px; } }

.form-control {
  font-size: inherit; }

.nowrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.back2top {
  cursor: pointer;
  position: fixed;
  transition: all 200ms ease-in-out 200ms;
  z-index: 10;
  font-size: 24px;
  right: 15px;
  bottom: 17px;
  line-height: 0;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  padding-top: 3px;
  background-color: #000;
  color: #fff;
  width: 34px;
  height: 34px; }

.back2top.active {
  opacity: 1;
  visibility: visible; }

.back2top.active:hover {
  background-color: #098d00; }

.dropdown {
  font-size: 13px; }

.dropdown__btn {
  position: relative;
  border: 1px solid #ebebeb;
  padding: 3px 15px 3px 7px;
  cursor: pointer; }

.dropdown__btn i {
  position: absolute;
  top: 50%;
  right: 7px;
  font-size: 15px;
  transform: translateY(-50%); }

.dropdown__menu {
  border: 1px solid #e8e8e8;
  opacity: 0;
  transition: all .3s;
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 3;
  background-color: #fff; }

.dropdown.active .dropdown__menu {
  opacity: 1;
  visibility: visible; }

.dropdown.active .dropdown__item a {
  height: 35px;
  padding: 3px 7px; }

.dropdown.active i:before {
  content: "\f106"; }

.dropdown__item ~ .dropdown__item a {
  border-top: 1px solid #e8e8e8; }

.dropdown__item a {
  padding: 0 7px;
  height: 0;
  transition: all .3s;
  line-height: 30px;
  color: #bfbfbf; }

@media screen and (min-width: 1024px) {
  .dropdown__item:hover a {
    background-color: #000;
    color: #fff; } }

.dropdown__item.active a {
  background-color: #000;
  color: #fff; }

.drop {
  position: relative; }

.drop-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  min-width: 3rem;
  background-color: rgba(35, 40, 45, 0.8); }

.drop-list .item:not(:last-child) {
  margin-bottom: 0.5rem; }

.drop-list .link {
  white-space: nowrap;
  display: block; }

.drop-list .link:hover {
  color: #098d00; }

.drop-list.child {
  right: auto;
  left: 100%;
  top: 0; }

.drop.right .drop-list {
  right: 0;
  left: auto; }

@media screen and (min-width: 992px) {
  .drop-list {
    display: block;
    visibility: hidden;
    opacity: 0;
    padding: 1rem; }
  .drop:hover > .drop-list {
    visibility: visible;
    animation: fadeUp 250ms linear 0s forwards; } }

.img_cen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.img_full {
  width: 100%;
  height: 100%; }

.img_fit {
  object-fit: cover; }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }

.embed-responsive:before {
  content: "";
  display: block; }

.hasLink {
  position: relative; }

.hasLink > .link {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 2; }

.hasBg .link-bg {
  z-index: 1; }

@media screen and (min-width: 1024px) {
  .hasBg .link-bg {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0; }
  .hasBg:hover .link-bg {
    opacity: 1; } }

.link-line {
  color: #222;
  position: relative;
  font-size: 12px;
  padding-left: 80px; }

.link-line:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background-color: #098d00;
  border-right: 4px solid #222;
  box-shadow: -3px 0 0 0 #fff inset;
  width: 60px;
  height: 2px;
  display: inline-block; }

.link:hover {
  color: #f00; }

.breadcrumb {
  margin-bottom: 0;
  background-color: #fff;
  padding: 15px 0; }

.breadcrumbk {
  font-size: 0.9375rem;
  color: #7e7e7e;
  background-color: #ebf7ea;
  padding: 0.75rem 1.5rem 0.5rem;
  margin-top: -2rem;
  margin-bottom: 2rem; }

.breadcrumbk.center .breadcrumbk-list {
  justify-content: center; }

.breadcrumbk-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.breadcrumbk-list .item {
  margin-bottom: 0.25rem; }

.breadcrumbk-list .item:not(:last-child):after {
  content: "/";
  margin-left: 1rem;
  margin-right: 1rem; }

.breadcrumbk-list .item.active {
  color: #098d00; }

.breadcrumbk-list .link:hover {
  color: #098d00; }

.paginationk {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.875rem; }

.paginationk.center {
  justify-content: center; }

.paginationk.center .paginationk-list {
  justify-content: center; }

.paginationk.right {
  justify-content: flex-end; }

.paginationk.right .paginationk-list {
  justify-content: flex-end; }

.paginationk-list {
  display: flex;
  flex-wrap: wrap; }

.paginationk-list .item:not(:last-child) {
  margin-right: 0.375rem; }

.paginationk-list .item:not(.dots) .link {
  display: flex;
  height: 1.75rem;
  width: 1.75rem;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border: 1px solid #e5e5e5;
  color: #b2b2b2;
  font-size: 0.8125rem;
  line-height: 1; }

.paginationk-list .item:not(.dots).active .link, .paginationk-list .item:not(.dots):hover .link {
  color: #fff;
  background-color: #098d00;
  border-color: #098d00; }

.paginationk-list .item.disabled {
  display: none; }

.paginationk-list .item.disabled .link {
  cursor: default;
  pointer-events: none; }

.paginationk-list .item.disabled:hover .link {
  color: #222;
  background-color: transparent; }

.paginationk-list .item:first-child .link:before, .paginationk-list .item:last-child .link:before {
  font-family: "FontAwesome"; }

.paginationk-list .item:first-child .link:before {
  content: "\f104"; }

.paginationk-list .item:last-child .link {
  flex-direction: row-reverse; }

.paginationk-list .item:last-child .link:before {
  content: "\f105"; }

.rating {
  position: relative;
  font-size: 12px; }

.rating .numb {
  font-size: 14px; }

.rating .rating-disabled .rating-stars {
  cursor: default !important; }

.rating .rating-md {
  font-size: unset !important; }

.rating .rating-container {
  display: inline-block; }

.rating .rating-container .empty-stars {
  color: #ff5722; }

.rating.without-caption .theme-krajee-uni .star {
  font-size: 20px;
  margin: 0 1px;
  line-height: 1; }

.rating.without-caption .rating-container .clear-rating,
.rating.without-caption .rating-container .caption {
  display: none; }

.rating.without-caption .rating-container .filled-stars {
  -webkit-text-stroke: 0;
  text-shadow: none;
  color: #ff5722; }

.flickity-page-dots {
  bottom: 0; }

.flickity-page-dots .dot {
  height: 9px;
  width: 9px;
  background-color: #ff5722;
  border: 1px solid #ff5722;
  box-shadow: none;
  opacity: 1;
  vertical-align: middle; }

.flickity-page-dots .dot.is-selected {
  height: 13px;
  width: 13px;
  box-shadow: 0 0 0 1px #fff inset; }

.slick-dots {
  margin-top: 1.25rem;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }

.slick-dots li {
  margin-left: 5px;
  margin-right: 5px; }

.slick-dots button {
  height: 0.3125rem;
  width: 1.5rem;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: none;
  opacity: 1;
  vertical-align: middle;
  font-size: 0;
  border-radius: 0.3125rem; }

.slick-dots .slick-active button {
  background-color: #098d00; }

.slick-slider {
  overflow: hidden; }

.slick-slider:hover .slick-arrow {
  opacity: 1; }

.slick-list {
  margin-left: -0.3125rem;
  margin-right: -0.3125rem; }

.slick-list .item {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem; }

.slick-track {
  display: flex;
  align-items: center; }

.slick-sync-for {
  margin-bottom: 1rem; }

.slick-sync-nav .item .img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: 250ms;
  background-color: rgba(0, 0, 0, 0.6); }

.slick-sync-nav .item.slick-current .img:after {
  opacity: 0; }

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.25rem;
  width: 1.25rem;
  font-size: 0;
  color: #b3b3b3;
  border-radius: 50%;
  z-index: 1;
  border: 1px solid #b3b3b3; }

.slick-arrow:before {
  font: 0.75rem "FontAwesome"; }

.slick-prev {
  left: 1.25rem; }

.slick-prev:before {
  content: "\f104"; }

.slick-next {
  right: 1.25rem; }

.slick-next:before {
  content: "\f105"; }

.title-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 1.25rem; }

.title-wrap .title {
  margin-bottom: 0;
  font-size: 1.125rem;
  font-weight: bold; }

.title-wrap .link {
  margin-left: auto;
  color: #098d00; }

.title-detail {
  font-size: 1.125rem;
  text-transform: uppercase;
  color: #f00;
  font-weight: bold;
  margin-bottom: 1rem;
  padding-top: 0.5rem; }

.title-detail-sm {
  font-size: 0.9375rem;
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0.75rem; }

.btn-main {
  color: #fff;
  background-color: #098d00;
  line-height: 1;
  padding: 0.8rem 2rem; }

.btn-main .fa {
  margin-left: 0.5rem; }

.btn-main:hover {
  filter: brightness(110%); }

.btn-cyan {
  color: #fff;
  background-color: #4db3d6;
  padding: 0.5rem;
  border-radius: 0.25rem; }

.btn-cyan:hover {
  filter: brightness(110%); }

.btn-blue {
  color: #fff;
  background-color: #3d3878;
  line-height: 1.2;
  padding: 0.8rem 2rem; }

.btn-blue .fa {
  margin-left: 0.5rem; }

.btn-blue:hover {
  border-radius: 0.5rem;
  color: #fff;
  background-color: rgba(61, 56, 120, 0.8); }

.btn-black-trans {
  background-color: rgba(0, 0, 0, 0.75);
  border: 1px solid #000;
  padding: 12px 37px;
  color: #fff;
  font-weight: 600; }

.btn-black-trans:hover {
  background-color: #fff;
  border-radius: 6px;
  transition: border-radius 250ms linear 150ms; }

.btn-red {
  color: #fff;
  background-color: #f00;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  border: 1px solid #f00;
  line-height: 1; }

.btn-red .zmdi {
  margin-right: 0.5rem; }

.btn-red:hover {
  color: #f00;
  background-color: #fff; }

.btn-white {
  color: #333;
  background-color: #fff;
  padding: 0.8rem 3rem; }

.btn-white:hover {
  color: #fff;
  background-color: #333;
  border-radius: 6px;
  transition: border-radius 250ms linear 150ms; }

.btn-white-trans {
  color: #333;
  font-weight: 600;
  border: 1px solid;
  padding: 0.8rem 3rem;
  display: inline-block; }

.btn-white-trans:hover {
  color: #fff;
  background-color: #333;
  border-radius: 6px;
  transition: border-radius 250ms linear 150ms; }

.btn-gray {
  color: #000;
  background-color: #f0f0f0;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #eee; }

.btn-gray:hover {
  filter: brightness(95%); }

.btn-reset {
  text-decoration: underline; }

.filter {
  background-color: #666;
  padding: 1.25rem 0; }

.filter-search {
  width: 100%;
  height: 2.5rem;
  display: flex;
  border: 1px solid #ededed;
  margin-bottom: 1rem; }

.filter-search .input {
  width: 100%;
  height: 100%;
  background-color: #ededed;
  border: none;
  padding: 0.5rem 0.75rem; }

.filter-search .button {
  height: 100%;
  flex: 0 0 2.5rem;
  max-width: 2.5rem;
  background-color: #666;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  line-height: 1; }

@media screen and (min-width: 768px) {
  .filter-search {
    flex: 0 0 25.625rem;
    max-width: 25.625rem;
    width: auto;
    margin-bottom: 0; } }

.filter-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto; }

.filter-container .selectbox {
  margin-left: auto;
  font-size: 0.875rem; }

.filter-container .btn-collapse {
  display: none; }

.filter-container .button {
  transition: all 250ms;
  background-color: #fff;
  padding: 0 1.25rem;
  white-space: nowrap;
  border-radius: 2px;
  height: 2rem;
  line-height: 2rem;
  border: 1px solid; }

.filter-container .button-black {
  color: #098d00; }

.filter-container .button-black:hover {
  background-color: #098d00; }

.filter-container .button-red {
  color: #f00; }

.filter-container .button-red:hover {
  background-color: #f00; }

.filter-container .button:hover {
  color: #fff; }

@media screen and (min-width: 1200px) {
  .filter-container .selectbox {
    font-size: 1rem;
    margin-bottom: 0.5rem; }
  .filter-container .btn-collapse {
    display: flex;
    align-items: center;
    color: #333;
    background-color: #ededed;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem; }
  .filter-container .btn-collapse:after {
    content: "\f2f9";
    font-family: "FontAwesome";
    line-height: 1;
    font-size: 20px;
    margin-left: 0.5rem;
    transform: rotate(-90deg);
    transition: transform 250ms; }
  .filter-container .btn-collapse.collapsed:after {
    transform: rotate(0); }
  .filter-container .button:not(:last-child) {
    margin-right: 0.5rem; } }

@media screen and (max-width: 1199px) {
  .filter-container .selectbox {
    height: 2rem;
    border-color: #098d00;
    background-color: #fff;
    border-radius: 0.25rem; } }

@media screen and (max-width: 1199px) {
  .filter-wrap {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    max-width: 22.5rem;
    z-index: 9;
    transform: rotateY(90deg);
    transform-origin: top right;
    transition: transform 350ms;
    background-color: #fff; }
  .filter-wrap.opened {
    transform: rotateY(0); } }

@media screen and (min-width: 1200px) {
  .filter-list {
    display: flex;
    align-items: center; } }

@media screen and (max-width: 1199px) {
  .filter-list {
    overflow-y: auto;
    margin-top: 2.5rem;
    margin-bottom: 4rem;
    height: calc(100% - 6.5rem); } }

.filter-item {
  position: relative; }

@media screen and (min-width: 1200px) {
  .filter-item:not(:last-child) {
    margin-right: 0.5rem; }
  .filter-item:nth-last-child(-n + 3) .filter-collapse {
    left: auto;
    right: 0; } }

.filter-collapse {
  font-size: 0.875rem; }

.filter-collapse .inner {
  padding: 1.25rem; }

.filter-collapse .title {
  margin-bottom: 1rem;
  white-space: nowrap;
  font-weight: 600; }

.filter-collapse .list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.filter-collapse .list .item {
  flex: 0 0 49%;
  max-width: 49%; }

.filter-collapse .ctrl {
  display: none; }

.filter-collapse .column:not(:last-child) {
  margin-bottom: 1.25rem; }

.filter-collapse.style2 .inner {
  padding: 0; }

.filter-collapse.style2 .column {
  padding: 1.25rem; }

.filter-collapse.style2 .ctrl {
  padding: 1.25rem; }

@media screen and (min-width: 1200px) {
  .filter-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    width: 22.5rem;
    background-color: #fff;
    z-index: 3;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); }
  .filter-collapse.style2 {
    width: 45rem; }
  .filter-collapse.style2 .inner {
    display: flex;
    flex-wrap: wrap; }
  .filter-collapse.style2 .column {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 1.25rem;
    border-bottom: 1px solid #ededed; }
  .filter-collapse.style2 .column:nth-child(odd) {
    border-right: 1px solid #ededed; }
  .filter-collapse.style2 .ctrl {
    flex: 0 0 100%;
    max-width: 100%;
    border-top: none;
    margin-top: 0; }
  .filter-collapse .ctrl {
    padding-top: 1.25rem;
    margin-top: 0.9375rem;
    border-top: 1px solid #ededed;
    text-align: right;
    display: block; } }

@media screen and (max-width: 1199px) {
  .filter-collapse {
    display: block; } }

.filter-open {
  height: 2rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  color: #098d00;
  background-color: #fff;
  border: 1px solid #098d00;
  border-radius: 0.25rem; }

.filter-open .zmdi {
  margin-right: 0.5rem; }

@media screen and (min-width: 1200px) {
  .filter-open {
    display: none; } }

.filter-close {
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  color: #fff;
  background-color: #098d00;
  position: fixed;
  left: 0;
  top: 0; }

.filter-close .zmdi {
  margin-right: 0.5rem;
  font-size: 1.5rem; }

@media screen and (min-width: 1200px) {
  .filter-close {
    display: none; } }

.filter-ctrl {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25); }

.filter-ctrl .button {
  width: 40%;
  margin: 0 5%; }

@media screen and (min-width: 1200px) {
  .filter-ctrl {
    display: none; } }

.filter-tags {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  padding: 0.5rem 0;
  background-color: #fff; }

.filter-tags .item {
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  color: #fff;
  background-color: #098d00;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem; }

.filter-tags .item:empty {
  display: none; }

.filter-tags .item:after {
  content: "\f136";
  font-family: "FontAwesome";
  margin-left: 0.5rem; }

.filter-tags .item:hover {
  background-color: #f00; }

@media screen and (min-width: 768px) {
  .filter-tags {
    padding: 0.5rem; } }

@media screen and (min-width: 992px) {
  .filter-tags {
    padding: 0.5rem 0.5rem 0.5rem 1rem; } }

.filter .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.tab-nav {
  border: 1px solid #eee; }

.tab-nav .list {
  display: flex;
  white-space: nowrap;
  overflow-x: auto; }

.tab-nav .item:not(:last-child) .link:before {
  content: "|";
  line-height: 1;
  color: #eee;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0; }

.tab-nav .link {
  padding: 0.875rem 2.5rem;
  display: block;
  width: 100%;
  color: #000;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1;
  position: relative;
  text-transform: capitalize;
  min-width: 9.375rem; }

.tab-nav .link.active {
  color: #fff;
  background-color: #098d00; }

.tab-nav .link:hover:before, .tab-nav .link.active:before {
  display: none; }

.tab-nav.ver {
  padding: 0; }

.tab-nav.ver .item:not(:last-child) {
  margin-right: 0;
  margin-bottom: 16px; }

.tab-nav.ver .link {
  padding: 15px; }

.tab-nav.center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }

@media screen and (min-width: 992px) {
  .tab-nav .link:hover {
    color: #fff;
    background-color: #098d00; } }

.tab-panel {
  visibility: hidden;
  height: 0;
  overflow: hidden; }

.tab-panel.fade {
  opacity: 0;
  transition: 500ms; }

.tab-panel.active {
  visibility: visible;
  height: auto;
  opacity: 1; }

.header {
  padding-top: 0.5rem; }

@media screen and (min-width: 768px) {
  .header {
    padding-top: 1.25rem; } }

.header-top {
  display: flex;
  padding-bottom: 1rem;
  flex-wrap: wrap; }

@media screen and (min-width: 768px) {
  .header-top {
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between; } }

.header-bot .title {
  height: 2.5rem;
  color: #fff;
  background-color: #098d00;
  font-size: 0.875rem;
  font-weight: bold;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem; }

.header-bot .title:before {
  content: "\f009";
  font-family: "FontAwesome";
  margin-right: 0.5rem;
  display: inline-block;
  font-weight: 400; }

.header-bot .list {
  height: 100%;
  overflow-x: auto;
  display: flex;
  align-items: center;
  border: 1px solid #098d00;
  padding: 0.5rem 1rem; }

.header-bot .list .item:not(:last-child):after {
  content: "|";
  line-height: 1;
  color: #e1e1e1;
  margin-left: 1.875rem;
  margin-right: 1.875rem; }

.header-bot .list .link {
  font-weight: bold;
  font-size: 0.875rem; }

@media screen and (min-width: 768px) {
  .header-bot {
    border: 1px solid #098d00; }
  .header-bot .title {
    height: 3.25rem;
    margin-bottom: 0; }
  .header-bot .title:before {
    content: ""; }
  .header-bot .list {
    border: none;
    padding: 0; } }

@media screen and (min-width: 1200px) {
  .header-bot .title {
    cursor: pointer; } }

.header-logo {
  flex: 0 0 12.5rem;
  max-width: 12.5rem;
  margin: 0 auto 1rem; }

@media screen and (min-width: 768px) {
  .header-logo {
    margin: 0; } }

@media screen and (min-width: 1200px) {
  .header-logo {
    flex: 0 0 17.75rem;
    max-width: 17.75rem; } }

.header-right {
  flex: 0 0 100%;
  max-width: 100%; }

.header-right .link {
  display: inline-block; }

@media screen and (min-width: 768px) {
  .header-right {
    flex: 0 0 32rem;
    max-width: 32rem; } }

@media screen and (min-width: 992px) {
  .header-right {
    flex: 0 0 47.5rem;
    max-width: 47.5rem; } }

@media screen and (min-width: 1200px) {
  .header-right {
    flex: 0 0 50rem;
    max-width: 50rem; } }

.header-contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem; }

.header-contact .fa {
  margin-right: 0.5rem; }

.header-contact .item .link:not(:last-child) {
  margin-bottom: 0.5rem; }

.header-contact .contact-group {
  font-size: 1rem;
  font-weight: bold;
  color: #f00; }

.header-contact .contact-group .fa {
  color: #098d00; }

.header-contact .contact-group .fa-phone-in-talk {
  height: 1.0625rem;
  width: 1.0625rem;
  background: url(../img/root/icon-phone-in-talk.png) no-repeat center; }

@media screen and (min-width: 576px) {
  .header-contact .item .link:not(:last-child) {
    margin-bottom: 0; }
  .header-contact .item .link:not(:last-child):after {
    content: "|";
    line-height: 1;
    color: #e1e1e1;
    margin-left: 0.75rem;
    margin-right: 0.75rem; } }

@media screen and (min-width: 992px) {
  .header-contact .item .link:not(:last-child):after {
    margin-left: 1.25rem;
    margin-right: 1.25rem; } }

.header-search {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }

.header-search .form-search {
  height: 2.5rem;
  width: 100%; }

.header-search .cart {
  display: flex;
  align-items: flex-start;
  margin-left: 1rem; }

.header-search .cart-icon {
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 0;
  background-color: #098d00;
  position: relative;
  border-radius: 0.375rem;
  background: #098d00 url(../img/root/icon-shopping-bag.png) no-repeat center; }

.header-search .cart-icon .quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  font-size: 0.875rem;
  color: #098d00;
  background-color: #fff;
  border: 1px solid #098d00;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: -0.625rem;
  right: -0.625rem;
  font-weight: bold; }

.header-search .cart-text {
  font-size: 0; }

.header-search .cart-text span {
  display: block; }

.header-search .cart-text span:not(:last-child) {
  margin-bottom: 0.375rem; }

.header-search .cart-text span.fb {
  font-weight: bold; }

@media screen and (min-width: 992px) {
  .header-search .form-search {
    flex: 0 0 33.125rem;
    max-width: 33.125rem;
    width: auto; }
  .header-search .cart {
    margin-left: 0; }
  .header-search .cart-icon {
    margin-right: 1.5rem; }
  .header-search .cart-text {
    font-size: 0.9375rem; } }

@media screen and (max-width: 575px) {
  .header .log {
    text-align: right; } }

@media screen and (min-width: 992px) {
  .navigation {
    background: url(../img/root/bg-nav.png) no-repeat center/cover; } }

.navigation-open {
  font-size: 1.125rem;
  line-height: 1;
  margin-left: 1rem;
  color: #fff;
  height: 2.5rem;
  width: 2.5rem;
  flex: 0 0 2.5rem;
  max-width: 2.5rem;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #098d00;
  border-radius: 0.4rem; }

.navigation-list {
  display: flex;
  flex-direction: column; }

.navigation-list .link {
  display: block; }

.navigation-list .link .fa-home {
  font-size: 1.125rem; }

.navigation-list .social-list {
  font-size: 1.25rem; }

.navigation-list .language {
  margin-bottom: 1rem;
  font-size: 0.9375rem; }

.navigation-list .language img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5rem; }

.navigation-list .language-btn {
  line-height: 1; }

.navigation-close {
  display: flex;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  color: #fff;
  background-color: #098d00;
  position: absolute;
  top: 0;
  left: 0; }

.navigation-close .zmdi {
  font-size: 1.5rem;
  margin-right: 0.5rem; }

@media screen and (min-width: 992px) {
  .navigation {
    color: #fff; }
  .navigation-list {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 3.125rem; }
  .navigation-list .item:last-child {
    display: flex;
    align-items: center; }
  .navigation .language {
    margin-bottom: 0; }
  .navigation .social-list:before {
    content: "|";
    display: inline-block;
    line-height: 1;
    margin-left: 1rem;
    margin-right: 1rem; }
  .navigation-close, .navigation-open {
    display: none; } }

@media screen and (max-width: 991px) {
  .navigation {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 360px;
    height: 100%;
    transition: transform 350ms;
    transform: rotateY(90deg);
    transform-origin: top right;
    box-shadow: 0 0 10px rgba(51, 51, 51, 0.5);
    z-index: 9;
    background-color: whitesmoke; }
  .navigation.opened {
    transform: rotateY(0); }
  .navigation-wrap {
    height: calc(100% - 2.5rem);
    margin-top: 2.5rem;
    overflow-y: auto; }
  .navigation-list {
    padding-top: 0.5rem; }
  .navigation-list > .item {
    padding: 0.5rem 0; }
  .navigation .drop-list .item:first-child {
    margin-top: 0.5rem; }
  .navigation .drop-list .item:last-child {
    margin-bottom: 0.5rem; }
  .navigation .container {
    height: 100%; } }

.footer {
  margin-top: auto;
  background-color: #f5f5f5;
  padding-top: 2.25rem;
  overflow: hidden; }

.footer-bot {
  color: #fff;
  background: url(../img/root/bg-footer.png) no-repeat center/cover; }

.footer-bot .container {
  min-height: 3.125rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }

@media screen and (min-width: 576px) {
  .footer-bot .container {
    justify-content: space-between; } }

.footer-title {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  user-select: none; }

@media screen and (min-width: 992px) {
  .footer-title button {
    pointer-events: none; } }

.footer-menu {
  margin-bottom: 1.5rem; }

.footer-menu .item {
  margin-bottom: 0.625rem; }

.footer-menu .link {
  display: flex;
  font-size: 0.9375rem; }

.footer-menu .link:before {
  content: "\f101";
  font-family: "FontAwesome";
  flex: 0 0 1.125rem;
  max-width: 1.125rem; }

.footer-menu .link.tel {
  margin-top: 0.625rem;
  font-weight: bold; }

.footer-menu .link.tel:before {
  content: ""; }

@media screen and (min-width: 992px) {
  .footer-menu {
    margin-bottom: 0; } }

.footer-info {
  margin-bottom: 1.5rem; }

.footer-info .item {
  display: flex;
  align-items: baseline;
  margin-bottom: 0.625rem; }

.footer-info .text {
  margin-bottom: 0; }

.footer-info .fa {
  flex: 0 0 1.5rem;
  max-width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #098d00;
  margin-right: 0.625rem;
  color: #098d00;
  font-size: 0.875rem; }

.footer-info .fa-bank {
  font-size: 0.6875rem; }

.footer-info .fa-envelope, .footer-info .fa-chrome {
  font-size: 0.75rem; }

@media screen and (min-width: 992px) {
  .footer-info {
    margin-bottom: 0; } }

.footer-block ~ .footer-block {
  margin-top: auto; }

.footer-block .collapse-btn:after {
  content: "\f107";
  font: 1rem "FontAwesome";
  margin-left: 0.5rem;
  display: inline-block;
  transition: transform 250ms; }

.footer-block .collapse-btn.collapsed:after {
  transform: rotate(-90deg); }

@media screen and (min-width: 992px) {
  .footer-block .collapse {
    display: block; }
  .footer-block .collapsed:after {
    content: ""; } }

.footer-col {
  display: flex;
  flex-direction: column; }

.footer-col .img-haveRegistered {
  margin-top: 1.5rem;
  margin-bottom: 2rem; }

@media screen and (min-width: 992px) {
  .footer-col {
    margin-bottom: 3.375rem; }
  .footer-col .img-haveRegistered {
    margin-top: 2rem;
    margin-bottom: 0; } }

.footer .copyright {
  margin-bottom: 0;
  text-align: center; }

.footer .social-list {
  margin-bottom: 0.5rem; }

.footer .social-list .link {
  height: 1.625rem;
  width: 1.625rem;
  line-height: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #098d00;
  background-color: #fff;
  border: 1px solid #098d00; }

.footer .social-list .link:hover {
  color: #fff;
  background-color: #098d00;
  border-color: #fff; }

@media screen and (min-width: 576px) {
  .footer .social-list {
    margin-bottom: 0; }
  .footer .copyright {
    text-align: right; } }

.aside-block:not(:last-child) {
  margin-bottom: 1.875rem; }

.aside-block img {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.aside-nav {
  border: 1px solid #eee; }

.aside-nav .title {
  color: #098d00;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.875rem;
  padding: 0.625rem 1.5625rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid #eee;
  margin-bottom: 0;
  line-height: 1.5;
  display: flex; }

.aside-nav .title:before {
  content: "\f196";
  font-family: "FontAwesome";
  font-weight: normal;
  flex: 0 0 1.25rem;
  max-width: 1.25rem; }

.aside-nav .title.style2 {
  color: #000; }

.aside-nav .title.style2:before {
  content: "\f0c9"; }

.aside-nav__list {
  font-size: 0.875rem;
  color: #2a2a2a;
  padding: 0.9375rem 0.625rem 0.9375rem 1.5625rem; }

.aside-nav__list > .item .collapse-title {
  display: flex;
  align-items: baseline;
  font-weight: bold;
  margin-bottom: 0.625rem; }

.aside-nav__list > .item .collapse-title:hover {
  color: #098d00; }

.aside-nav__list > .item .collapse-btn {
  flex: 0 0 1rem;
  max-width: 1rem;
  font-size: 0.8125rem;
  text-align: left; }

.aside-nav__list > .item .collapse-btn:before {
  content: "\f105";
  font-family: "FontAwesome";
  display: inline-block;
  transition: transform 250ms;
  transform: rotate(90deg); }

.aside-nav__list > .item .collapse-btn.collapsed:before {
  transform: rotate(0); }

.aside-nav__list .list {
  padding-left: 2.5rem; }

.aside-nav__list .list .item {
  margin-bottom: 0.75rem; }

.aside-open {
  padding: 0.5rem 1rem;
  color: #fff;
  background-color: #098d00;
  border-radius: 0.25rem;
  display: block;
  font-size: 0.875rem;
  position: sticky;
  top: 6.5625rem;
  z-index: 3;
  margin-left: auto; }

.aside-close {
  display: flex;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  color: #fff;
  background-color: #098d00;
  position: absolute;
  top: 0;
  left: 0; }

.aside-ads .aside-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.75rem;
  margin-right: -0.75rem; }

.aside-ads .aside-block {
  padding: 0;
  text-align: center;
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 0.75rem;
  padding-right: 0.75rem; }

@media screen and (min-width: 992px) {
  .aside-ads .aside-block {
    flex: 0 0 100%;
    max-width: 100%; } }

@media screen and (min-width: 992px) {
  .aside-close, .aside-open {
    display: none; }
  .aside-ads .aside-wrap {
    top: 5rem; } }

@media screen and (max-width: 991px) {
  .aside {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    max-width: 22.5rem;
    transform-origin: top right;
    transform: rotateY(90deg);
    transition: 500ms;
    padding: 0;
    z-index: 9;
    background-color: #fff; }
  .aside.opened {
    transform: rotate(0); }
  .aside-wrap {
    height: calc(100% - 2.5rem);
    margin-top: 2.5rem;
    overflow-y: auto; } }

@media screen and (min-width: 992px) {
  .js-asideTrigger {
    pointer-events: none; } }

.banner-main .slick-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.5rem; }

@media screen and (min-width: 768px) {
  .banner-main .slick-dots {
    bottom: 2rem; } }

.social-list {
  display: flex;
  align-items: center; }

.social-list .item:not(:last-child) {
  margin-right: 1.25rem; }

.social-share {
  background-color: #f6f6f6;
  border: 1px solid #e1e1e1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.75rem 1.25rem 0.5rem;
  min-height: 3.125rem;
  border-radius: 0.5rem; }

.social-share .text {
  font-weight: bold;
  font-size: 0.8125rem;
  color: #7e7e7e;
  margin-right: 0.5rem; }

.social-share .list {
  display: flex;
  align-items: center;
  font-size: 1.25rem; }

.social-share .list .item {
  margin-bottom: 0.25rem; }

.social-share .list .item:not(:last-child) {
  margin-right: 0.5rem; }

.social-share .download {
  display: flex;
  height: 1.5625rem;
  background-color: #f00;
  border-radius: 0.25rem;
  flex: 0 0 6.0625rem;
  max-width: 6.0625rem;
  margin-right: auto;
  color: #fff; }

.social-share .download .cap {
  flex: 0 0 4.5rem;
  max-width: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8125rem; }

.social-share .download .fa {
  height: 100%;
  flex: 0 0 1.5625rem;
  max-width: 1.5625rem;
  background-color: #000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }

.social-share .download .fa:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -2px;
  height: 0.3125rem;
  width: 0.3125rem;
  background-color: #000;
  transform: rotate(45deg);
  display: inline-block; }

.social-share.right {
  justify-content: flex-end; }

.main {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.section:not(:last-child) {
  margin-bottom: 1.875rem; }

.hasShadow {
  box-shadow: 0 0 0.5rem #eee; }

.contact-box {
  background-color: #fafafa;
  text-align: center;
  margin: 1.25rem 1.25rem 0 1.25rem;
  padding: 1.125rem; }

.contact-box .name {
  color: #098d00;
  font-size: 0.9375rem;
  font-weight: bold;
  margin-bottom: 0.625rem; }

.contact-box .phone {
  color: #f00;
  font-size: 0.9375rem;
  font-weight: bold;
  margin-bottom: 0.9375rem;
  display: block; }

.contact-app {
  display: flex;
  align-items: center;
  justify-content: center; }

.contact-app .item:not(:last-child) {
  margin-right: 0.75rem; }

.support {
  padding-bottom: 1.25rem; }

.support img + .contact-box {
  margin-top: 0.75rem; }

.support .title {
  font: 1.875rem "UTM Bebas";
  color: #fff;
  background-color: #098d00;
  text-transform: uppercase;
  padding: 0.625rem;
  line-height: 1;
  text-align: center; }

.partner {
  background-color: #ebebeb;
  min-height: 5.4375rem;
  display: flex;
  align-items: center; }

.partner-slider {
  padding-left: 4.375rem;
  padding-right: 4.375rem;
  flex: 0 0 100%;
  max-width: 100%; }

.partner-slider .item {
  opacity: 0.6;
  transition: opacity 250ms; }

.partner-slider .item:hover {
  opacity: 1; }

.detail {
  font-size: 0.875rem;
  line-height: 1.7; }

.detail .img {
  margin-bottom: 1rem; }

.detail p {
  margin-bottom: 2.1875rem; }

@media screen and (min-width: 768px) {
  .detail .social-share {
    margin-top: 4.6875rem; } }

.service-top {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap; }

.service-top .img {
  margin-bottom: 1rem; }

@media screen and (min-width: 768px) {
  .service-top {
    flex-wrap: nowrap; }
  .service-top .text {
    padding-right: 1.875rem; }
  .service-top .img {
    flex: 0 0 23.125rem;
    max-width: 23.125rem; } }

.captcha {
  flex: 0 0 5.625rem;
  max-width: 5.625rem;
  color: #000;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 0.875rem;
  border-radius: 0 0.25rem 0.25rem 0;
  border: 1px solid #e1e1e1;
  border-left: none; }

.captcha-box {
  display: flex; }

.captcha-box .form-control {
  border-radius: 0.25rem 0 0 0.25rem; }

.fa-remove-black {
  background: url(../img/root/icon-remove-black.png) no-repeat center/cover;
  height: 0.625rem;
  width: 0.625rem;
  display: inline-block; }

.fa-remove-black:hover {
  background-image: url(../img/root/icon-remove-red.png); }

.fa-remove-red {
  background: url(../img/root/icon-remove-red.png) no-repeat center/cover;
  height: 0.625rem;
  width: 0.625rem;
  display: inline-block; }

.product-panel {
  padding: 0 1rem 1rem;
  border: 1px solid #eee; }

.product-panel ~ .product-panel {
  border-top: none; }

.product-panel .title-wrap {
  margin: 0 -1rem 1.5rem;
  padding: 0.5rem	1rem;
  border-bottom: 1px solid #eee;
  color: #f00; }

.product-panel .title:before {
  content: "\f101";
  font-family: "FontAwesome";
  margin-right: 0.5rem; }

.product-panel__inner {
  padding: 1.25rem 1rem 0; }

.product-panel.style2 {
  padding: 0; }

.product-panel.style2 ~ .product-panel {
  margin-top: 1.875rem;
  border-top: 1px solid #e1e1e1; }

.product-panel.style2 > .title {
  padding: 0.5rem 1rem;
  color: #098d00;
  font-size: 0.875rem;
  font-weight: bold;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  line-height: 1.7; }

.product-panel.style2 > .title:before {
  content: "\f196";
  flex: 0 0 1.25rem;
  max-width: 1.25rem;
  margin-right: 0;
  font-weight: normal; }

.product-panel.style2 .name {
  text-transform: capitalize; }

@media screen and (min-width: 768px) {
  .product-panel {
    padding-left: 1.75em;
    padding-right: 1.75em; }
  .product-panel .title-wrap {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .product-panel__inner {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .product-panel.style2 > .title {
    padding-left: 1.75rem;
    padding-right: 1.75rem; } }

.product-item {
  margin-bottom: 1.5rem; }

.product-item .name {
  font-weight: bold;
  margin-bottom: 1rem;
  display: block; }

.product-item .wrap {
  display: flex;
  align-items: flex-start; }

.product-item .wrap .img {
  flex: 0 0 6.875rem;
  max-width: 6.875rem;
  height: 6.875rem;
  margin-bottom: 0; }

.product-item .wrap .summary {
  padding-left: 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.7;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box; }

.product-list {
  margin-bottom: 0.9375rem; }

.product-list .product-list {
  margin-top: 1.875rem; }

.product-list .title {
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  display: flex;
  line-height: 1.7;
  text-transform: uppercase; }

.product-list .title:before {
  content: "\f005";
  color: #098d00;
  flex: 0 0 1.25rem;
  max-width: 1.25rem;
  margin-right: 0; }

.product-list .list {
  font-size: 0.875rem;
  text-transform: capitalize; }

.product-list .item {
  margin-bottom: 0.9375rem;
  display: flex;
  align-items: baseline; }

.product-list .item:before {
  content: "\f111";
  font-family: "FontAwesome";
  font-size: 0.25rem;
  flex: 0 0 1.25rem;
  max-width: 1.25rem;
  margin-bottom: 0.125rem;
  padding-left: 0.25rem; }

.product-item2 {
  display: flex;
  border: 1px solid #eee;
  border-top: none;
  flex-direction: column; }

.product-item2 .img {
  flex: 0 0 9.375rem;
  max-width: 9.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto; }

.product-item2 .content {
  display: flex;
  flex-direction: column;
  padding: 0.9375rem 0.6875rem 0.9375rem 0.9375rem;
  font-size: 0.875rem;
  width: 100%; }

.product-item2 .content p {
  margin-bottom: 0.25rem; }

.product-item2 .content .price {
  color: #f00;
  font-weight: bold; }

.product-item2 .content .name {
  font-size: 0.9375rem;
  font-weight: bold;
  color: #098d00; }

.product-item2 .content .code {
  font-weight: bold; }

.product-item2 .content .btn-main {
  height: 2.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 0;
  margin-top: 0.875rem;
  border-radius: 0.375rem; }

@media screen and (min-width: 768px) {
  .product-item2 {
    flex-direction: row; }
  .product-item2 .img {
    margin: 0;
    border-right: 1px solid #eee; }
  .product-item2 .content {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    flex-direction: row; } }

.product-item2__col {
  padding-left: 0.5 0.0625rem;
  padding-right: 0.5 0.0625rem;
  margin-bottom: 0.5rem; }

@media screen and (min-width: 992px) {
  .product-item2__col:nth-child(1) {
    flex: 0 0 36%;
    max-width: 36%; }
  .product-item2__col:nth-child(2) {
    flex: 0 0 34%;
    max-width: 34%; }
  .product-item2__col:nth-child(3) {
    flex: 0 0 30%;
    max-width: 30%; } }

@media screen and (min-width: 1200px) {
  .product-item2__col {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    margin-bottom: 0; }
  .product-item2__col:nth-child(1) {
    flex: 0 0 43%;
    max-width: 43%; }
  .product-item2__col:nth-child(2) {
    flex: 0 0 31%;
    max-width: 31%; }
  .product-item2__col:nth-child(3) {
    flex: 0 0 26%;
    max-width: 26%; } }

.product-table {
  background-color: #ebf7ea;
  overflow-y: auto;
  max-height: 19.375rem; }

@media screen and (min-width: 768px) {
  .product-table {
    max-height: 25.8125rem; } }

.product-table__row {
  display: flex;
  padding: 0.5rem;
  padding-bottom: 0.5rem; }

.product-table__row ~ .product-table__row {
  border-bottom: 1px solid #d3ded2; }

.product-table__row .item {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem; }

.product-table__row .item:nth-child(1) {
  flex: 0 0 1.5rem;
  max-width: 1.5rem; }

.product-table__row .item:nth-child(4) {
  flex: 0 0 1.5rem;
  max-width: 1.5rem;
  justify-content: flex-end; }

.product-table__row .item:nth-child(3) {
  flex: 0 0 3.125rem;
  max-width: 3.125rem; }

.product-table__row .form-control {
  max-width: 3.125rem;
  flex: 0 0 3.125rem;
  height: 2.125rem;
  text-align: center; }

.product-table__row .img {
  height: 3.125rem;
  flex: 0 0 3.125rem;
  max-width: 3.125rem;
  padding: 0.25rem;
  background-color: #fff;
  margin-bottom: 0; }

.product-table__row .content {
  padding-left: 0.5rem;
  font-size: 0.8125rem; }

.product-table__row .content .name {
  font-weight: bold;
  color: #098d00; }

.product-table__row.head {
  display: none;
  color: #fff;
  background-color: #098d00;
  position: sticky;
  top: 0; }

.product-table__row.head .item {
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 2.5rem; }

.product-table__row.head .item + .item {
  border-left: 1px solid #ebf7ea; }

.product-table__row.foot {
  flex-direction: row;
  border-bottom: none;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: #ebf7ea; }

.product-table__row.foot .item {
  font-weight: bold;
  min-height: 40px;
  padding-top: 0;
  padding-bottom: 0; }

.product-table__row.foot .item:first-child {
  justify-content: flex-start;
  flex: 0 0 100%;
  max-width: 100%; }

.product-table__row.foot .item:before {
  display: none; }

.product-table__row.foot .item .value {
  min-width: 4.375rem;
  text-align: center; }

@media screen and (min-width: 576px) {
  .product-table__row {
    flex-direction: row;
    padding: 0; }
  .product-table__row .item {
    width: 100%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    min-height: 6.875rem;
    display: flex;
    align-items: center;
    margin-bottom: 0; }
  .product-table__row .item:before {
    display: none; }
  .product-table__row .item:nth-child(1), .product-table__row .item:nth-child(4) {
    flex: 0 0 3.125rem;
    max-width: 3.125rem;
    justify-content: center; }
  .product-table__row .item:nth-child(3) {
    flex: 0 0 6.125rem;
    max-width: 6.125rem; }
  .product-table__row .form-control {
    height: 2.5rem;
    max-width: 4.375rem;
    flex: 0 0 4.375rem;
    margin-left: auto;
    margin-right: auto; }
  .product-table__row .img {
    height: 4.375rem;
    flex: 0 0 4.375rem;
    max-width: 4.375rem;
    padding: 0.5rem; }
  .product-table__row .content {
    padding-left: 1.5rem;
    font-size: 0.9375rem; }
  .product-table__row.head {
    display: flex; }
  .product-table__row.foot .item:first-child {
    flex: 0 0 13.25rem;
    max-width: 13.25rem; } }

.product-modal .modal-header {
  position: relative;
  min-height: 3.125rem;
  display: flex;
  align-items: center;
  padding: 0 1.25rem;
  font-size: 1.125rem;
  color: #000;
  background-color: #ebf7ea; }

.product-modal .modal-header .fa {
  margin-right: 0.625rem;
  color: #f00; }

.product-modal .modal-header .close {
  opacity: 1;
  float: none;
  width: 2rem;
  height: 2.0625rem;
  background: url(../img/root/icon-close-modal.png) no-repeat center/cover;
  position: absolute;
  top: -0.725rem;
  right: -0.725rem;
  margin: 0; }

.product-modal .modal-body, .product-modal .modal-footer {
  padding: 0; }

.product-modal .modal-footer {
  margin-top: auto;
  background-color: #ebf7ea;
  padding: 1.25rem 0.75rem 0.75rem;
  flex-direction: column; }

.product-modal .modal-footer > :not(:first-child) {
  margin-left: 0; }

.product-modal .modal-footer > :not(:last-child) {
  margin-right: 0; }

.product-modal .modal-footer .form-request {
  width: 100%; }

.product-modal .modal-footer .ctrl {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 0.875rem; }

.product-modal .modal-footer .ctrl .back,
.product-modal .modal-footer .ctrl .remove-all {
  margin-bottom: 0.5rem; }

.product-modal .modal-footer .ctrl .fa {
  margin-right: 0.625rem; }

.product-modal .modal-footer .ctrl .back {
  color: #4db3d6; }

.product-modal .modal-footer .ctrl .remove-all {
  color: #f00; }

.product-modal .modal-dialog {
  width: 48.125rem;
  max-width: calc(100% - 1.25rem); }

.product-modal .modal-title {
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

@media screen and (min-width: 768px) {
  .product-modal .modal-title {
    text-align: left;
    padding-bottom: 0;
    padding-top: 0; } }

.product-modal .modal-content {
  display: flex;
  flex-direction: column; }

@media screen and (min-width: 768px) {
  .product-modal .modal-content {
    min-height: 34.375rem; } }

.product-general {
  padding-top: 1.875rem; }

.product-general .img {
  flex: 0 0 16.875rem;
  max-width: 16.875rem;
  height: 16.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  margin-left: auto;
  margin-right: auto; }

.product-general .title {
  color: #4db3d6;
  text-transform: uppercase;
  font-size: 0.9375rem;
  font-weight: bold;
  padding-top: 0.25rem;
  margin-bottom: 0.5rem; }

.product-general .summary {
  font-size: 0.875rem; }

.product-general .summary p {
  margin-bottom: 0.5rem; }

.product-general .block:not(:last-child) {
  margin-bottom: 1rem; }

.product-general ~ .social-share {
  margin-top: 1.5rem; }

@media screen and (min-width: 576px) {
  .product-general {
    display: flex;
    align-items: flex-start; }
  .product-general .img {
    margin: 0; }
  .product-general .content {
    padding-left: 1.875rem; } }

.product-document {
  padding-top: 1.75rem; }

.product-document .title {
  font-size: 0.9375rem;
  color: #4db3d6;
  font-weight: bold;
  margin-bottom: 1rem; }

.product-document__table {
  border: 1px solid #e1e1e1; }

.product-document__row {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  flex-direction: column; }

.product-document__row ~ .product-document__row {
  border-top: 1px solid #e1e1e1; }

.product-document__row .fa {
  color: #f00;
  margin-right: 0.25rem; }

.product-document__row .item {
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem; }

.product-document__row .item:before {
  content: attr(data-title);
  flex: 0 0 6.875rem;
  max-width: 6.875rem;
  font-weight: bold; }

@media screen and (min-width: 576px) {
  .product-document__row .item {
    padding-right: 0.625rem;
    display: block;
    margin-bottom: 0; }
  .product-document__row .item:before {
    display: none; }
  .product-document__row .item:nth-child(1) {
    flex: 0 0 3rem;
    max-width: 3rem;
    text-align: center;
    padding-right: 0; }
  .product-document__row .item:nth-child(2), .product-document__row .item:nth-child(4), .product-document__row .item:nth-child(5) {
    flex: 0 0 6rem;
    max-width: 6rem; } }

@media screen and (min-width: 768px) {
  .product-document__row .item:nth-child(1) {
    flex: 0 0 4.375rem;
    max-width: 4.375rem; }
  .product-document__row .item:nth-child(2), .product-document__row .item:nth-child(4) {
    flex: 0 0 9rem;
    max-width: 9rem; }
  .product-document__row .item:nth-child(5) {
    flex: 0 0 7.25rem;
    max-width: 7.25rem; } }

.product-document__row.head {
  background-color: #f0f0f0;
  font-weight: bold;
  display: none; }

@media screen and (min-width: 576px) {
  .product-document__row {
    min-height: 2.5rem;
    flex-direction: row;
    padding-left: 0;
    padding-right: 0; }
  .product-document__row.head {
    display: flex; } }

.product-related .title {
  font-weight: bold;
  margin-bottom: 0.75rem;
  font-size: 0.9375rem; }

.product-related .product-item2 {
  border-top: 1px solid #eee; }

@media screen and (min-width: 768px) {
  .product-related .product-item2__col:nth-child(3) {
    flex: 0 0 11.25rem;
    max-width: 11.25rem; } }

@media screen and (min-width: 992px) {
  .product-related .product-item2__col:nth-child(1) {
    width: 100%;
    flex: auto;
    max-width: none; }
  .product-related .product-item2__col:nth-child(2) {
    flex: 0 0 30%;
    max-width: 30%; } }

.product-detail .block:not(:last-child) {
  margin-bottom: 1rem; }

.product-detail .top {
  margin-bottom: 2rem !important; }

.product-detail .top .img {
  height: 20rem;
  width: 20rem;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin: auto auto 1.25rem; }

.product-detail .top .content {
  padding-top: 0.5rem;
  font-size: 0.875rem; }

.product-detail .top .cap {
  font-weight: bold;
  flex: 0 0 9.0625rem;
  max-width: 9.0625rem; }

.product-detail .top .price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #f00; }

.product-detail .top .fa-reply {
  transform: rotateY(180deg);
  margin-right: 0.5rem; }

.product-detail .top .link {
  color: #7e7e7e;
  font-size: 0.875rem; }

.product-detail .top .link:hover {
  color: #f00; }

.product-detail .top .btn-cyan {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2.5rem;
  margin-top: 1.25rem;
  font-weight: bold; }

.product-detail .top .btn-main {
  flex: 0 0 11.875rem;
  max-width: 11.875rem;
  padding: 0;
  border-radius: 0.25rem; }

.product-detail .top .btn-gray {
  flex: 0 0 8rem;
  max-width: 8rem; }

.product-detail .top .btn-main, .product-detail .top .btn-gray {
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold; }

.product-detail .top .btn-main .fa, .product-detail .top .btn-gray .fa {
  margin-right: 0.5rem; }

@media screen and (min-width: 768px) {
  .product-detail .top {
    display: flex;
    align-items: flex-start; }
  .product-detail .top .img {
    height: 23.75rem;
    flex: 0 0 23.75rem;
    max-width: 23.75rem;
    margin: 0;
    width: auto; }
  .product-detail .top .content {
    flex: 0 0 22.5rem;
    max-width: 22.5rem;
    padding-left: 1.25rem; } }

@media screen and (min-width: 992px) {
  .product-detail .top .img {
    height: 29.375rem;
    flex: 0 0 29.375rem;
    max-width: 29.375rem; }
  .product-detail .top .content {
    flex: 0 0 25rem;
    max-width: 25rem;
    padding-left: 4.125rem; } }

.img img {
  transition: filter 250ms; }

.img:hover img {
  filter: brightness(0.9); }

.quantity {
  display: flex;
  height: 2.5rem;
  border: 1px solid #eee;
  border-radius: 0.375rem;
  overflow: hidden; }

.quantity-btn {
  flex: 0 0 2.5rem;
  max-width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  background-color: #f0f0f0; }

.quantity-val {
  width: 100%;
  border: 1px solid #eee;
  text-align: center; }

.news-index__list {
  border: 1px solid #e1e1e1;
  border-bottom: none;
  display: flex;
  flex-wrap: wrap; }

.news-index__item {
  padding: 0.875rem 1.125rem;
  border-bottom: 1px solid #e1e1e1;
  flex: 0 0 100%;
  max-width: 100%;
  display: flex; }

.news-index__item .img {
  flex: 0 0 7rem;
  max-width: 7rem;
  height: auto; }

.news-index__item .content {
  padding-left: 1rem; }

.news-index__item .date {
  color: #098d00;
  font-size: 0.8125rem;
  margin-bottom: 0.3125rem; }

.news-index__item .name {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #000; }

.news-index__item .summary {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.7;
  display: none; }

.news-index__item .more {
  color: #098d00;
  font-weight: bold; }

.news-index__item:hover .name,
.news-index__item:hover .more {
  color: #f00; }

.news-index__item:hover .more {
  text-decoration: underline; }

@media screen and (min-width: 768px) {
  .news-index__item {
    flex: 0 0 50%;
    max-width: 50%; }
  .news-index__item:nth-child(odd) {
    border-right: 1px solid #e1e1e1; }
  .news-index__item .img {
    flex: 0 0 10.625rem;
    max-width: 10.625rem;
    height: 9.375rem; }
  .news-index__item .summary {
    display: block; } }

.news-related {
  margin-top: 1.5rem; }

.news-related .about-item.style2:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none; }

.about-video {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 39.75rem; }

.about-video:before {
  padding-top: 56.28931%; }

.about-video img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.about-video iframe {
  width: 100%;
  height: 100%; }

.about-item {
  display: flex;
  align-items: flex-start;
  flex-direction: column; }

.about-item:not(:last-child) {
  padding-bottom: 1.875rem;
  margin-bottom: 1.875rem;
  border-bottom: 1px solid #eee; }

.about-item .img {
  margin-left: auto;
  margin-right: auto; }

.about-item .title {
  font-weight: bold;
  margin-bottom: 1.5rem;
  font-size: 0.9375rem;
  padding-top: 0.25rem; }

.about-item .summary {
  font-size: 0.875rem;
  line-height: 1.7;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.about-item.style2:last-child {
  padding-bottom: 1.875rem;
  margin-bottom: 1.875rem;
  border-bottom: 1px solid #eee; }

.about-item.style2 .summary {
  -webkit-line-clamp: 4; }

.about-item.style2 .title {
  padding-top: 0.5rem; }

@media screen and (min-width: 576px) {
  .about-item {
    flex-direction: row; }
  .about-item .img {
    margin: 0;
    flex: 0 0 16.875rem;
    max-width: 16.875rem; }
  .about-item .content {
    padding-left: 1.875rem; } }

.about .img {
  margin-bottom: 1.875rem; }

.about p {
  margin-bottom: 2.1875rem; }

.about .title-lg, .about .title-sm {
  font-weight: bold;
  color: #098d00;
  margin-bottom: 1.5rem;
  font-size: 0.9375rem; }

.about .title-lg {
  text-transform: uppercase; }

.about .title-sm {
  text-decoration: underline; }

.about .title-video {
  color: #f00;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 0.9375rem;
  font-weight: bold; }

@media screen and (min-width: 768px) {
  .about .title-video {
    margin-top: 4.6875rem; } }

.contact-mapbox {
  height: 400px;
  width: 100%;
  position: relative;
  margin-bottom: 1.875rem; }

.contact-mapbox iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%; }

.contact-info__row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem; }

.contact-info__row .caption {
  flex: 0 0 6.875rem;
  max-width: 6.875rem;
  font-weight: bold; }

.contact-info__row .text {
  width: 100%; }

.contact-title {
  margin-bottom: 1.875rem; }

.contact-title.black {
  color: #000; }
