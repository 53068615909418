.breadcrumb {
	margin-bottom: 0;
	background-color: #fff;
	padding: 15px 0;

	// +media(fhd, emp, 0)
	//  padding: 15*$unit 0

	// &_content
	//  display: flex
	//  flex-wrap: wrap
	//  align-items: center

	//  li
	//    position: relative
	//    text-transform: uppercase
	//    // font-family: $fl

	//    &:not(:last-child)
	//      &:after
	//        content: '>'
	//        padding: 0 5px

	//        // +media(fhd, emp, 0)
	//        //  padding: 0 5*$unit

	&k {
		font-size: 15*$rem;
		color: $cl_7e;
		background-color: #ebf7ea;
		padding: 12*$rem 1.5rem 8*$rem;
		margin-top: -2rem;
		margin-bottom: 2rem;

		&.center {
			.breadcrumbk-list {
				justify-content: center; } }

		&-list {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			// padding-top: 10*$rem
			// padding-bottom: 20*$rem

			.item {
				margin-bottom: 0.25rem;

				&:not(:last-child) {
					&:after {
						content: "/";
						margin-left: 1rem;
						margin-right: 1rem; } }

				&.active {
					color: $cl_main; } }

			.link {
				&:hover {
					color: $cl_main; } } } } }
